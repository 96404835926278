<template>
  <div class="pa-1 pa-lg-5">
    <div v-if="!msg.guessed">
    <v-card v-if="!msg.show" tile outlined elevation="1" class="py-5 green rounded-lg" :height="$isMobile() ? '120' : '160'" @click="onClick(msg.select)">
      <v-img
        :src="require('../assets/images/maloha1.png')"
        contain
        :height="$isMobile() ? '80' : '120'"
      />
      <!-- {{this.msg.sound}} -->
      <!-- :src="require('../assets/images/maloha1.png')" -->
      <!-- <h3 class="py-3 white--text">Maloha</h3> -->
    </v-card>
    <v-card v-if="msg.show" tile outlined elevation="1" class="py-5 rounded-lg" :height="$isMobile() ? '120' : '160'" v-on:click="onClick(msg.select)">
      <v-row v-if="msg.img">
        <v-col>
          <v-img
            :src="msg.img"
            contain
            :height="$isMobile() ? '70' : '90'"
            v-if="msg.text"
          />
          <h3 v-if="msg.text" class="py-0 py-lg-3">{{ msg.text }} - {{ msg.pinyin }}</h3>
          <v-img
            :src="msg.img"
            contain
            :height="$isMobile() ? '75' : '110'"
            v-else
          />
        </v-col>
      </v-row>
      <v-row v-else class="text-center" justify="center">
        <v-col cols="12" class="col-lg-6">
          <h2 class="rounded-xl green lighten-3 mx-2 my-lg-3">{{ msg.text }}</h2>
        </v-col>
        <v-col v-if="msg.pinyin.length < 8" cols="12" class="col-lg-8 mt-0 pt-0">
          <h2 class="rounded-xl green lighten-3 mx-2">{{ msg.pinyin }}</h2>
        </v-col>
        <v-col v-else cols="12" class="col-lg-8 mt-0 pt-0">
          <h3 class="rounded-xl green lighten-3 mx-2">{{ msg.pinyin }}</h3>
        </v-col>
      </v-row>
    </v-card>
    <audio ref="audioElm" :src="require('@/' + this.msg.sound)"></audio>
    </div>
    <div v-if="msg.guessed">
      <v-card tile outlined elevation="1" class="py-5 rounded-lg" :height="$isMobile() ? '120' : '160'" @click="playSound()" style="opacity:0.3;">
        <v-row v-if="msg.img">
          <v-col>
            <v-img
              :src="msg.img"
              contain
              :height="$isMobile() ? '70' : '90'"
              v-if="msg.text"
            />
            <h3 v-if="msg.text" class="py-0 py-lg-3">{{ msg.text }}</h3>
            <v-img
              :src="msg.img"
              contain
              :height="$isMobile() ? '75' : '110'"
              v-else
            />
          </v-col>
        </v-row>
        <v-row v-else class="text-center" justify="center">
          <v-col cols="12" class="col-lg-6">
            <h2 class="rounded-xl green lighten-3 mx-2 my-lg-3">{{ msg.text }}</h2>
          </v-col>
          <v-col v-if="msg.pinyin.length < 8" cols="12" class="col-lg-8 mt-0 pt-0">
            <h2 class="rounded-xl green lighten-3 mx-2">{{ msg.pinyin }}</h2>
          </v-col>
          <v-col v-else cols="12" class="col-lg-8 mt-0 pt-0">
            <h3 class="rounded-xl green lighten-3 mx-2">{{ msg.pinyin }}</h3>
          </v-col>
        </v-row>
      </v-card>
      <audio ref="audioElm" :src="require('@/' + this.msg.sound)"></audio>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HelloWorld',
    props: {
      msg: Object,
    },
    methods: {
      onClick(state){
        this.playSound()
        if (state == true){
          this.msg.show = false
          this.msg.select = false
        } else {
          this.msg.show = true
          this.msg.select = true
        }
      },
      playSound () {
        if (this.$refs.audioElm.paused){
          this.$refs.audioElm.play();
          // this.songPaused = this.$refs.audioElm.paused;
        } else {
          this.$refs.audioElm.pause();
          this.$refs.audioElm.currentTime = 0;
          // this.songPaused = this.$refs.audioElm.paused;
        }
      }
    }
  }
</script>
