<template>
  <v-container>
    <!-- <router-link class="green--text" to="/"><h2>Back</h2></router-link> -->
    <!-- <h1>Stories</h1> -->
    <v-row v-if="hsk == 1" align="center" justify="center" class="mb-lg-15 mt-1 pb-lg-15" style="height:180px">
        <v-col cols="6" lg="4" align="center" justify="center" class="mb-0 pa-1 pa-lg-5">
          <v-hover v-slot="{ hover }">
            <v-card outlined :elevation="hover ? 1 : 0" class="pa-lg-4 pa-1 rounded-xl" to="/onestory">
                <v-img
                  alt="rice"
                  contain
                  src="https://static.vecteezy.com/system/resources/thumbnails/000/272/352/small_2x/poke-bowl.jpg"
                  transition="scale-transition"
                  height="150"
                />
                Rice and tea
            </v-card>
          </v-hover>
        </v-col>
        <v-col cols="6" lg="4" align="center" justify="center" class="mb-0 pa-1 pa-lg-5">
          <v-hover v-slot="{ hover }">
            <v-card outlined :elevation="hover ? 1 : 0" class="pa-lg-4 pa-1 rounded-xl" to="/storytwo">
                <v-img
                  alt="supermarket"
                  contain
                  src="https://img.freepik.com/free-vector/supermarket-interior-with-food-shelves-vector_81522-485.jpg?size=626&ext=jpg"
                  transition="scale-transition"
                  height="150"
                />
                At the supermarket
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" class="mt-0 mt-lg-15 pt-lg-15 pt-0" style="height:180px">
        <v-col v-if="hsk == 1" cols="6" lg="4" align="center" justify="center" class="mb-0 mt-10 mt-lg-0 pa-1 pa-lg-5">
          <v-hover v-slot="{ hover }">
            <v-card outlined :elevation="hover ? 1 : 0" class="rounded-xl" to="/storythree" :disabled="true">
                <v-card-title class="striped" style="margin-bottom: -38px; z-index: 10; position: relative; height: 55px;">
                  <p v-if="!$isMobile()">Under construction</p> <p v-else>Construction.</p>
                </v-card-title>
                <v-img
                  alt="meeting"
                  contain
                  src="https://img.freepik.com/free-vector/young-women-meeting_89224-2610.jpg?size=626&ext=jpg"
                  transition="scale-transition"
                  height="150"
                />
                Meeting people
            </v-card>
          </v-hover>
        </v-col>
        <v-col v-if="hsk == 2" cols="6" lg="4" align="center" justify="center" class="mb-0 pa-1 pa-lg-5">
          <v-hover v-slot="{ hover }">
            <v-card outlined :elevation="hover ? 1 : 0" class="pa-lg-4 pa-1 rounded-xl" to="/storyfour">
                <!-- <v-card-title class="striped" style="margin-bottom: -38px; z-index: 10; position: relative; height: 55px;">
                  <p v-if="!$isMobile()">Under construction</p> <p v-else>Construction.</p>
                </v-card-title> -->
                <v-img
                  alt="meeting"
                  contain
                  src="https://i.pinimg.com/originals/49/6b/6e/496b6ea27aea7693c82a1afe8ae872d1.gif"
                  transition="scale-transition"
                  height="150"
                />
                Signs
            </v-card>
          </v-hover>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // import HelloWorld from '../components/HelloWorld'
  import store from '../store'

  export default {
    name: 'Home',

    components: {
      // HelloWorld,
    },
    computed: {
      hsk () {
        return store.state.hsk
      },
    }
  }
  // pa-lg-4 pa-1 voor wanneer niet meer under construction
</script>

<style lang="scss" scoped>
.striped{
  background: repeating-linear-gradient(
    45deg,
    #ffee8d,
    #ffee8d 10px,
    #cecece 10px,
    #cecece 20px
  );
}
</style>