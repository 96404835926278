<template>
  <v-container class="py-1">
    <v-row class="text-center">
        <v-card>
          <v-img
            :src="require('@/' + this.msg.photo)"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="200px"
          >
            <v-list-item three-line>
              <v-list-item-content class="white--text align-end">
                <!-- <v-card-title v-text="this.msg.name"></v-card-title> -->
                <!-- <v-list-item-title class="headline mb-1"><p class="white--text">{{this.msg.name}}</p></v-list-item-title> -->
                <v-list-item-subtitle class=" mb-5">
                  <h3 v-if="lang === 'NL'" class="px-0 white--text">{{this.msg.name}}</h3>
                  <h3 v-if="lang === 'EN'" class="px-0 white--text">{{this.msg.description.en}}</h3>
                  <h2 v-if="lang === 'Chinese'" class="px-0 white--text">{{this.msg.description.cn}}</h2>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-img>

          <v-card-actions class="justify-center">
            <!-- <v-spacer></v-spacer> -->
            <v-btn text>
              Play! <v-icon class="pl-1">mdi-music</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>

      <!-- <v-col offset="1" cols="10">
        <v-img
          :src="require('@/' + this.msg.photo)"
          class="my-3"
          contain
          height="120"
        />
      </v-col> -->

<!-- {{this.msg.photo}} -->

      <!-- <v-col class="mb-4">
        <h3 class="font-weight-bold mb-3">
          {{ this.msg.name }}
        </h3>

        <p class="subheading font-weight-regular">
          {{ this.msg.description.en}}
        </p>
      </v-col> -->

      <!-- <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          What's next?
        </h2>

        <v-row justify="center">
          <a
            v-for="(next, i) in whatsNext"
            :key="i"
            :href="next.href"
            class="subheading mx-3"
            target="_blank"
          >
            {{ next.text }}
          </a>
        </v-row>
      </v-col> -->

    </v-row>
  </v-container>
</template>

<script>
import store from '../store'
  export default {
    name: 'DishCard',

    data: () => ({
      
    }),
    props: {
      msg: Object,
    },
    computed: {
      show () { 
        return store.state.navigation.show
      },
      lang () {
        return store.state.language
      }
    }
  }
</script>
