<template>
  <v-container>
    <!-- <router-link class="green--text" to="/games"><h2>Back</h2></router-link> -->
    <v-row>
      <v-col cols="12" class="col-lg-5">
        <!-- <h1 v-if="!$isMobile()" class="red--text">Memory</h1> -->
        <pop-up :msg="this.message" :img="this.image"/>
        <pop-up-return :msg="this.message" :points="this.points" back="games"/>
      </v-col>
      <!-- <v-col cols="12" class="col-lg-5"><h1 class="green--text">Points: {{this.points}}%</h1></v-col> -->
      <!-- <v-col v-if="!$isMobile()" cols="2" class="mt-5">
        <h3 v-if="this.setFound == true" class="green--text">YES! A SET!</h3>
        <h3 v-if="this.setFound == false" class="red--text">Not a set, too bad..</h3>
        <h3 v-if="this.setFound == null">Click a card</h3>
      </v-col> -->
    </v-row>
    <!-- <v-row v-if="this.cardSet.filter(element => element.guessed == false).length == 0" class="text-center">
      <v-col>
        <v-btn dark color="green" v-on:click="startAgain()">
          <h3>Play again</h3>
        </v-btn>
      </v-col>
    </v-row> -->
    <v-row class="text-center mt-1 px-1" justify="center">
      <v-col
        v-for="(card, i) in cardSet"
        :key="i"
        :href="card.href"
        class="pa-0"
        cols="4" lg="3"
        @click="turnCard(card)"
      >
        <MemoryCard :msg="card"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MemoryCard from '../components/MemoryCard';
import PopUp from '../components/PopUp';
import PopUpReturn from '../components/PopUpReturn';
import store from '../store'

export default {
  name: 'MemoryCanvas',
  components: {
    MemoryCard,
    PopUp,
    PopUpReturn,
  },
  created: function () {
    // let array = this.cards
    let array = this.cards.filter(element => element.level == store.state.level && element.hsk == store.state.hsk)

    let currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    this.cardSet = array;
  },
  methods: {
    // reloadPage(){
    //   window.location.reload()
    // },
    startAgain(){
      let array = this.cards.filter(element => element.level == store.state.level && element.hsk == store.state.hsk)
      // console.log(array)

      for (let i = 0; i < array.length; i++) {
        array[i].show = false
        array[i].select = false
        array[i].guessed = false
        // console.log(array[i])
      }
      this.cardsTurned = 0
      this.setFound = null
      this.cardSet = null
      // this.points = 100

      let currentIndex = array.length, temporaryValue, randomIndex;
      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }
      this.cardSet = array;
    },
    turnCard(card){
      // console.log(card.show)
      if (card.select == true){
        // console.log(card.show)
        if (this.cardsTurned == 0){
          this.cardsTurned = 1
          // console.log(this.cardsTurned)
        } else {
          this.cardsTurned = 2
          // console.log(this.cardsTurned)
          const result = this.cardSet.filter(element => element.select == true);
            if (result[0].set == result[1].set){
                this.setFound = true
                // this.points = this.points + 5;

                let cardOne = this.cardSet.filter(element => element.select == true)[0]
                cardOne.select = false

                let cardTwo = this.cardSet.filter(element => element.select == true)[0]
                cardTwo.select = false
                
                this.cardsTurned = 0
                this.setFound = null

                setTimeout( () => {
                  // this.cardSet.splice(this.cardSet.findIndex(element => element.show == true), 1);
                  // this.cardSet.splice(this.cardSet.findIndex(element => element.show == true), 1);

                  cardOne.guessed = true
                  cardTwo.guessed = true

                  cardOne.show = false
                  cardTwo.show = false


                // console.log(this.cardSet.filter(element => element.guessed == false).length)

                if (this.cardSet.filter(element => element.guessed == false).length == 0){
                  this.gameOver()
                }

                  // console.log(this.cardSet.filter(element => element.show == true))

                  // this.cardSet.filter(element => element.show == true)[0].guessed = true
                  // this.cardSet.filter(element => element.show == true)[0].show = false

                  // console.log(this.cardSet.filter(element => element.show == true))

                  // this.cardSet.filter(element => element.show == true)[0].guessed = true
                  // this.cardSet.filter(element => element.show == true)[0].show = false

                  // console.log(this.cardSet.filter(element => element.show == true))

                  // cardOne.guessed = true
                  // cardOne.show = false
                  // let cardTwo = this.cardSet.findIndex(element => element.show == true)
                  // cardTwo.guessed = true
                  // cardTwo.show = false
                }, 1000);
              } else {
                this.setFound = false
                // this.points = this.points - 5;

                // let cardOne = this.cardSet.findIndex(element => element.select == true)
                // this.cardSet[cardOne].select = false
                // let cardTwo = this.cardSet.findIndex(element => element.select == true)
                // this.cardSet[cardTwo].select = false

                let cardOne = this.cardSet.filter(element => element.select == true)[0]
                cardOne.select = false
                let cardTwo = this.cardSet.filter(element => element.select == true)[0]
                cardTwo.select = false

                this.cardsTurned = 0
                this.setFound = null

                setTimeout( () => {
                  cardOne.show = false
                  cardTwo.show = false
                }, 1000);
              }
        }
      } else {
          if (this.cardsTurned == 2){
            this.cardsTurned = 1
            this.setFound = null
          } else {
            this.cardsTurned = 0
          }
      }
    },
    gameOver() {
      store.commit('GAMEOVER', true);
    }
  },
  data: () => ({
    cardsTurned: 0,
    setFound: null,
    cardSet: null,
    points: 20,
    message: "Find the two matching pictures.",
    image: "assets/images/memory1-expl.png",
    cards: [
      {
        level: 0,
        hsk: 1,
        id: '1',
        set: '1',
        text: '一',
        pinyin: 'Yī',
        img: 'https://www.pinclipart.com/picdir/middle/16-161946_animal-clip-art-number-one-vector-site-number.png',
        back: '../assets/logo.svg',
        sound: "assets/sentences/1-yi.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: '2',
        set: '1',
        text: '一',
        pinyin: 'Yī',
        img: 'https://www.pinclipart.com/picdir/middle/16-161946_animal-clip-art-number-one-vector-site-number.png',
        back: '../assets/logo.svg',
        sound: "assets/sentences/1-yi.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: '3',
        set: '2',
        text: '二',
        pinyin: 'Èr',
        img: 'https://i.pinimg.com/originals/7c/23/2b/7c232bbc2fb9ec5f35948233bc037f50.png',
        back: '../assets/logo.svg',
        sound: "assets/sentences/2-er.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: '4',
        set: '2',
        text: '二',
        pinyin: 'Èr',
        img: 'https://i.pinimg.com/originals/7c/23/2b/7c232bbc2fb9ec5f35948233bc037f50.png',
        back: '../assets/logo.svg',
        sound: "assets/sentences/2-er.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: '5',
        set: '3',
        text: '三',
        pinyin: 'Sān',
        img: 'https://media.istockphoto.com/photos/neon-3d-font-blue-and-pink-neon-light-3d-rendering-number-3-picture-id1250020951?k=6&m=1250020951&s=170667a&w=0&h=I_UW6D-gOxDv_wJnpg0rwRpcOpaX7UPG1ml5DLP-Qmk=',
        back: '../assets/logo.svg',
        sound: "assets/sentences/3-san.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: '6',
        set: '3',
        text: '三',
        pinyin: 'Sān',
        img: 'https://media.istockphoto.com/photos/neon-3d-font-blue-and-pink-neon-light-3d-rendering-number-3-picture-id1250020951?k=6&m=1250020951&s=170667a&w=0&h=I_UW6D-gOxDv_wJnpg0rwRpcOpaX7UPG1ml5DLP-Qmk=',
        back: '../assets/logo.svg',
        sound: "assets/sentences/3-san.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: '7',
        set: '4',
        text: '四',
        pinyin: 'Sì',
        img: 'https://cdn.iconscout.com/icon/free/png-512/4-four-digital-number-numerical-numbers-36017.png',
        back: '../assets/logo.svg',
        sound: "assets/sentences/4-si.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: '8',
        set: '4',
        text: '四',
        pinyin: 'Sì',
        img: 'https://cdn.iconscout.com/icon/free/png-512/4-four-digital-number-numerical-numbers-36017.png',
        back: '../assets/logo.svg',
        sound: "assets/sentences/4-si.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: '9',
        set: '5',
        text: '五',
        pinyin: 'Wǔ',
        img: 'https://images-na.ssl-images-amazon.com/images/I/71OubAsefxL._AC_SL1500_.jpg',
        back: '../assets/logo.svg',
        sound: "assets/sentences/5-wu.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: '10',
        set: '5',
        text: '五',
        pinyin: 'Wǔ',
        img: 'https://images-na.ssl-images-amazon.com/images/I/71OubAsefxL._AC_SL1500_.jpg',
        back: '../assets/logo.svg',
        sound: "assets/sentences/5-wu.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: '11',
        set: '6',
        text: '六',
        pinyin: 'Liù',
        img: 'https://cdn.pixabay.com/photo/2013/07/12/16/22/number-150795_640.png',
        back: '../assets/logo.svg',
        sound: "assets/sentences/6-liu.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: '12',
        set: '6',
        text: '六',
        pinyin: 'Liù',
        img: 'https://cdn.pixabay.com/photo/2013/07/12/16/22/number-150795_640.png',
        back: '../assets/logo.svg',
        sound: "assets/sentences/6-liu.mp3",
        show: false,
        select: false,
        guessed: false,
      },

      {
        level: 2,
        id: '1',
        set: '1',
        text: '苹果',
        pinyin: 'Píngguǒ',
        img: 'https://media.istockphoto.com/photos/red-apple-with-leaf-isolated-on-white-background-picture-id185262648?k=6&m=185262648&s=170667a&w=0&h=iJfV0M0WkvcsFLBOMJQUquqL_1xHNZSbYLiXjpiwAjA=',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/yu-fish.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 2,
        id: '2',
        set: '1',
        text: '苹果',
        pinyin: 'Píngguǒ',
        img: 'https://media.istockphoto.com/photos/red-apple-with-leaf-isolated-on-white-background-picture-id185262648?k=6&m=185262648&s=170667a&w=0&h=iJfV0M0WkvcsFLBOMJQUquqL_1xHNZSbYLiXjpiwAjA=',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/yu-fish.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 1,
        hsk: 1,
        id: '1',
        set: '1',
        text: '商店',
        pinyin: 'Shāngdiàn',
        img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Store_Building_Flat_Icon_Vector.svg/1024px-Store_Building_Flat_Icon_Vector.svg.png',
        back: '../assets/logo.svg',
        sound: "assets/sentences3/shangdian.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 1,
        hsk: 1,
        id: '2',
        set: '1',
        text: '商店',
        pinyin: 'Shāngdiàn',
        img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Store_Building_Flat_Icon_Vector.svg/1024px-Store_Building_Flat_Icon_Vector.svg.png',
        back: '../assets/logo.svg',
        sound: "assets/sentences3/shangdian.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 1,
        hsk: 1,
        id: '3',
        set: '2',
        text: '茶',
        pinyin: 'Chá',
        img: 'https://media.istockphoto.com/vectors/green-tea-in-cup-and-green-leaves-vector-illustration-in-flat-design-vector-id1157013982?b=1&k=6&m=1157013982&s=612x612&w=0&h=Acn8wqQt17FDf3uUkQJnpuC6gpp3-tYDAkqI5bHAJzA=',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/cha-tea.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 1,
        hsk: 1,
        id: '4',
        set: '2',
        text: '茶',
        pinyin: 'Chá',
        img: 'https://media.istockphoto.com/vectors/green-tea-in-cup-and-green-leaves-vector-illustration-in-flat-design-vector-id1157013982?b=1&k=6&m=1157013982&s=612x612&w=0&h=Acn8wqQt17FDf3uUkQJnpuC6gpp3-tYDAkqI5bHAJzA=',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/cha-tea.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 1,
        hsk: 1,
        id: '5',
        set: '3',
        text: '饭',
        pinyin: 'fàn',
        img: 'https://media.istockphoto.com/vectors/rice-bowl-vector-id547231286?b=1&k=6&m=547231286&s=612x612&w=0&h=oG90dozDg7BKAA-XS74gMyD2PuesQnr6tEwR-WRSicw=',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/fan.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 1,
        hsk: 1,
        id: '6',
        set: '3',
        text: '饭',
        pinyin: 'fàn',
        img: 'https://media.istockphoto.com/vectors/rice-bowl-vector-id547231286?b=1&k=6&m=547231286&s=612x612&w=0&h=oG90dozDg7BKAA-XS74gMyD2PuesQnr6tEwR-WRSicw=',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/fan.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 1,
        hsk: 1,
        id: '7',
        set: '4',
        text: '水',
        pinyin: 'Shuǐ',
        img: 'https://img.freepik.com/free-vector/glass-water-icon_68196-604.jpg?size=338&ext=jpg',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/shui-water.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 1,
        hsk: 1,
        id: '8',
        set: '4',
        text: '水',
        pinyin: 'Shuǐ',
        img: 'https://img.freepik.com/free-vector/glass-water-icon_68196-604.jpg?size=338&ext=jpg',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/shui-water.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 1,
        hsk: 1,
        id: '9',
        set: '5',
        text: '不',
        pinyin: 'Bù',
        img: 'https://img.freepik.com/free-vector/stop-sign-icon-notifications-that-anything_68708-468.jpg?size=338&ext=jpg&ga=GA1.2.1356500412.1617148800',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/bu-not.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 1,
        hsk: 1,
        id: '10',
        set: '5',
        text: '不',
        pinyin: 'Bù',
        img: 'https://img.freepik.com/free-vector/stop-sign-icon-notifications-that-anything_68708-468.jpg?size=338&ext=jpg&ga=GA1.2.1356500412.1617148800',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/bu-not.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 1,
        hsk: 1,
        id: '11',
        set: '6',
        text: '热',
        pinyin: 'Rè',
        img: 'https://whitehorsenetball.org.au/wp-content/uploads/2019/03/weather-2.jpg',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/ri-hot.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 1,
        hsk: 1,
        id: '12',
        set: '6',
        text: '热',
        pinyin: 'Rè',
        img: 'https://whitehorsenetball.org.au/wp-content/uploads/2019/03/weather-2.jpg',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/ri-hot.mp3",
        show: false,
        select: false,
        guessed: false,
      },

      {
        level: 0,
        hsk: 2,
        id: '1',
        set: '1',
        text: '出口',
        pinyin: 'Chūkǒu',
        img: 'https://www.omni-hk.com/image/cache/catalog/Products/Emergency%20Lighting%20Series/Automatic%20Emergency%20Light/LEDX-301-500x500.png',
        back: '../assets/logo.svg',
        sound: "assets/sentences3/chukou.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 2,
        id: '2',
        set: '1',
        text: '出口',
        pinyin: 'Chūkǒu',
        img: 'https://www.omni-hk.com/image/cache/catalog/Products/Emergency%20Lighting%20Series/Automatic%20Emergency%20Light/LEDX-301-500x500.png',
        back: '../assets/logo.svg',
        sound: "assets/sentences3/chukou.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 2,
        id: '3',
        set: '2',
        text: '入口',
        pinyin: 'Rùkǒu',
        img: 'https://www.freevector.com/uploads/vector/preview/27709/Entrance_vector_1.jpg',
        back: '../assets/logo.svg',
        sound: "assets/sentences3/rukou.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 2,
        id: '4',
        set: '2',
        text: '入口',
        pinyin: 'Rùkǒu',
        img: 'https://www.freevector.com/uploads/vector/preview/27709/Entrance_vector_1.jpg',
        back: '../assets/logo.svg',
        sound: "assets/sentences3/rukou.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 2,
        id: '5',
        set: '3',
        text: '鱼',
        pinyin: 'Yú',
        img: 'https://media.istockphoto.com/vectors/cute-fish-character-cartoon-vector-illustration-vector-id1045561036?b=1&k=6&m=1045561036&s=612x612&w=0&h=g3o38iDSBk1n-_ak0orDkS2FoPo9U7HvyObIGtQQeWg=',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/yu-fish.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 2,
        id: '6',
        set: '3',
        text: '鱼',
        pinyin: 'Yú',
        img: 'https://media.istockphoto.com/vectors/cute-fish-character-cartoon-vector-illustration-vector-id1045561036?b=1&k=6&m=1045561036&s=612x612&w=0&h=g3o38iDSBk1n-_ak0orDkS2FoPo9U7HvyObIGtQQeWg=',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/yu-fish.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 2,
        id: '7',
        set: '4',
        text: '小心',
        pinyin: 'Xiǎoxīn',
        img: 'https://media.istockphoto.com/photos/be-careful-sign-watch-your-steps-picture-id612524656',
        back: '../assets/logo.svg',
        sound: "assets/sentences3/xiaoxin.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 2,
        id: '8',
        set: '4',
        text: '小心',
        pinyin: 'Xiǎoxīn',
        img: 'https://media.istockphoto.com/photos/be-careful-sign-watch-your-steps-picture-id612524656',
        back: '../assets/logo.svg',
        sound: "assets/sentences3/xiaoxin.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 2,
        id: '9',
        set: '5',
        text: '冰',
        pinyin: 'Bīng',
        img: 'https://www.freevector.com/uploads/vector/preview/30450/Sketchy-Ice-Cubes.jpg',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/bing-cold.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 2,
        id: '10',
        set: '5',
        text: '冰',
        pinyin: 'Bīng',
        img: 'https://www.freevector.com/uploads/vector/preview/30450/Sketchy-Ice-Cubes.jpg',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/bing-cold.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 2,
        id: '11',
        set: '6',
        text: '面',
        pinyin: 'Miàn',
        img: 'https://image.freepik.com/vrije-vector/aziatische-traditionele-gerechten-met-noedels-en-eetstokjes_29190-4960.jpg',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/mian.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 2,
        id: '12',
        set: '6',
        text: '面',
        pinyin: 'Miàn',
        img: 'https://image.freepik.com/vrije-vector/aziatische-traditionele-gerechten-met-noedels-en-eetstokjes_29190-4960.jpg',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/mian.mp3",
        show: false,
        select: false,
        guessed: false,
      },



      // {
      //   id: '1',
      //   set: '1',
      //   text: '苹果 - Píngguǒ',
      //   img: 'https://nieuws.kuleuven.be/nl/campuskrant/1415/08/hoeveel-energie-verbruikt-je-appel/image',
      //   back: '../assets/logo.svg',
      //   sound: "assets/sentences/ni_hao.mp3",
      //   show: false,
      // },
      // {
      //   id: '2',
      //   set: '1',
      //   text: '苹果 - Píngguǒ',
      //   img: 'https://nieuws.kuleuven.be/nl/campuskrant/1415/08/hoeveel-energie-verbruikt-je-appel/image',
      //   back: '../assets/logo.svg',
      //   sound: "assets/sentences/zai_jian.mp3",
      //   show: false,
      // },
      // {
      //   id: '3',
      //   set: '2',
      //   text: '梨 - Lí',
      //   img: 'https://fruitmasters.com/nl/wp-content/uploads/sites/3/2020/04/2020_Packshot_Abate_500x500px-300x300.png',
      //   back: '../assets/logo.svg',
      //   sound: "assets/sentences/zai_jian.mp3",
      //   show: false,
      // },
      // {
      //   id: '4',
      //   set: '2',
      //   text: '梨 - Lí',
      //   img: 'https://fruitmasters.com/nl/wp-content/uploads/sites/3/2020/04/2020_Packshot_Abate_500x500px-300x300.png',
      //   back: '../assets/logo.svg',
      //   sound: "assets/sentences/zai_jian.mp3",
      //   show: false,
      // },
      //         {
      //   id: '5',
      //   set: '3',
      //   text: '香蕉 - Xiāngjiāo',
      //   img: 'https://banaan.nl/wp-content/uploads/banaan.png',
      //   back: '../assets/logo.svg',
      //   sound: "assets/sentences/zai_jian.mp3",
      //   show: false,
      // },
      // {
      //   id: '6',
      //   set: '3',
      //   text: '香蕉 - Xiāngjiāo',
      //   img: 'https://banaan.nl/wp-content/uploads/banaan.png',
      //   back: '../assets/logo.svg',
      //   sound: "assets/sentences/zai_jian.mp3",
      //   show: false,
      // },
      // {
      //   id: '7',
      //   set: '4',
      //   text: '奇异果 - Qíyì guǒ',
      //   img: 'https://www.goedvoorogen.nl/app/uploads/2019/03/heelkiwi.png',
      //   back: '../assets/logo.svg',
      //   sound: "assets/sentences/zai_jian.mp3",
      //   show: false,
      // },
      // {
      //   id: '8',
      //   set: '4',
      //   text: '奇异果 - Qíyì guǒ',
      //   img: 'https://www.goedvoorogen.nl/app/uploads/2019/03/heelkiwi.png',
      //   back: '../assets/logo.svg',
      //   sound: "assets/sentences/zai_jian.mp3",
      //   show: false,
      // },
      // {
      //   id: '9',
      //   set: '5',
      //   text: '樱桃 - Yīngtáo',
      //   img: 'https://www.veggipedia.nl/assets/Uploads/Products/7481a78ded/kersen-fruit-veggipedia-2.jpg',
      //   back: '../assets/logo.svg',
      //   sound: "assets/sentences/zai_jian.mp3",
      //   show: false,
      // },
      // {
      //   id: '10',
      //   set: '5',
      //   text: '樱桃 - Yīngtáo',
      //   img: 'https://www.veggipedia.nl/assets/Uploads/Products/7481a78ded/kersen-fruit-veggipedia-2.jpg',
      //   back: '../assets/logo.svg',
      //   sound: "assets/sentences/zai_jian.mp3",
      //   show: false,
      // },
      // {
      //   id: '11',
      //   set: '6',
      //   text: '覆盆子 - Fù pénzi',
      //   img: 'https://www.limburgsmaaktnaarmeer.be/site/data/images/product/thumb_framboos.png',
      //   back: '../assets/logo.svg',
      //   sound: "assets/sentences/zai_jian.mp3",
      //   show: false,
      // },
      // {
      //   id: '12',
      //   set: '6',
      //   text: '覆盆子 - Fù pénzi',
      //   img: 'https://www.limburgsmaaktnaarmeer.be/site/data/images/product/thumb_framboos.png',
      //   back: '../assets/logo.svg',
      //   sound: "assets/sentences/zai_jian.mp3",
      //   show: false,
      // },
    ],
  }),
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* h3 { */
  /* margin: 40px 0 0; */
/* } */
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
