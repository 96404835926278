<template>
  <div class="pa-0 pa-lg-15 mb-0">
    <!-- <v-card tile outlined elevation="1" class="py-5" height="160" > -->
      <!-- <v-row> -->
        <v-card class="text-center mt-lg-15 mt-3 mb-0">
          <v-img
            src="https://media.istockphoto.com/vectors/sound-icon-flat-volume-sign-symbol-music-player-sticker-vector-id1193300702?b=1&k=6&m=1193300702&s=612x612&w=0&h=SjNhPfb9j0CqukVZTNxr1GGNyXY754--bYfFNx8Abio="
            contain
            height="110"
            style="cursor: pointer;"
            @click="playSound()"
          />
          <!-- <h2 class="white--text">Click me!</h2> -->
        </v-card>
      <!-- </v-row> -->
    <!-- </v-card> -->
    <audio ref="audioElm" :src="require('@/' + this.msg.sound)"></audio>
  </div>
</template>

<script>
  export default {
    name: 'HelloWorld',
    props: {
      msg: Object,
    },
    methods: {
      playSound () {
        if (this.$refs.audioElm.paused){
          this.$refs.audioElm.play();
          // this.songPaused = this.$refs.audioElm.paused;
        } else {
          this.$refs.audioElm.pause();
          this.$refs.audioElm.currentTime = 0;
          // this.songPaused = this.$refs.audioElm.paused;
        }
      }
    }
  }
</script>
