<template>
  <v-container>
    <!-- <router-link class="green--text" to="/games"><h2>Back</h2></router-link> -->
    <pop-up :msg="this.message" :img="this.image" />
    <pop-up-return :msg="this.message" :points="this.points" back="games"/>
    <!-- <v-row> -->
      <!-- <v-col cols="12" class="col-lg-5"><h1 class="red--text">Search Game</h1></v-col> -->
    <!-- </v-row> -->
    <!-- <v-row v-if="this.gameOver" class="text-center mt-15 pt-15">
      <v-col cols="12">
      <h1>Well done! You got {{this.points}} points out of 10!</h1>
      </v-col>
      <v-col cols="12">
        <v-btn dark color="green" v-on:click="playAgain()">
          <h3>Play again</h3>
        </v-btn>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col cols="7" lg="3">
        <v-btn v-if="$isMobile()" :disabled="disableButton" class="ml-1 white--text" style="position:absolute; margin-top:80px" color="green" v-on:click="randomCards()">
          <h3 class="white--text">Next</h3>
        </v-btn>
        <v-container class="text-center mt-lg-5 pt-lg-15 pt-0 mt-0 ml-lg-0 ml-15 pl-10 pl-lg-0" justify="center">
        <!-- <h2>Try to find the:</h2> -->
          <v-col v-if="!$isMobile()" cols="1" class="col-lg-12 text-center mt-0" style="position:absolute;"><h2 class="red--text rounded-xl red lighten-4" style="width:70px">&#165; {{this.points}}</h2></v-col>
          <!-- <v-col v-if="!$isMobile()" class="col-lg-12 text-right"><h1 class="green--text">Points: {{this.points}}/10</h1></v-col> -->
          <SoundCard :msg="this.shuffledCards[this.randomIndex]"/>
          <v-btn v-if="!$isMobile()" :disabled="disableButton" class="mt-lg-5 mt-3 white--text" color="green" v-on:click="randomCards()">
            <h3>Next</h3>
          </v-btn>
        </v-container>
      </v-col>
      <v-col v-if="$isMobile()" cols="1" class="col-lg-5 mb-5 ml-3 mt-2 text-center" style="position:absolute;"><h2 class="red--text rounded-xl red lighten-4" style="width:70px">&#165; {{this.points}}</h2></v-col>
      <!-- yellow--text text--lighten-2 rounded-xl red darken-4 -->
      
      <!-- <v-col v-if="$isMobile()" cols="5" class="mt-0 text-center"> -->
          <!-- <v-col class="col-lg-5 mb-5 text-center"><h1 class="black--text">{{this.points}}/10</h1></v-col> -->
          <!-- <h2 v-if="this.cardfound == true" class="green--text rounded-xl green lighten-4 pa-3">好的! <v-icon large color="green">mdi-emoticon-happy mdi-emoticon-excited</v-icon><br><p>Hěn hǎo!</p></h2>
          <h2 v-if="this.cardfound == false" class="red--text rounded-xl red lighten-4 pa-3">不好。<v-icon large color="red">mdi-emoticon-sad</v-icon><br><p>Bù hǎo.</p></h2>
          <h2 v-if="this.cardfound == null" class="white--text">. <v-icon large color="grey">mdi-emoticon-happy</v-icon><br><p>.</p></h2>
      </v-col> -->
          <div cols="2" class="mt-lg-10 mt-3 mb-5" style="position:absolute; z-index: 1;" :style="$isMobile() ? 'margin-left: 230px;' : 'margin-left : 260px;'">
            <h2 v-if="this.cardfound == true" class="green--text rounded-xl green lighten-4 pa-3">好的! <v-icon large color="green">mdi-emoticon-happy</v-icon><br>Hěn hǎo!</h2>
            <h3 v-if="this.cardfound == false" class="red--text text--lighten-4 rounded-xl red lighten-1 pa-3">不好。<v-icon large color="red lighten-4 ">mdi-emoticon-sad</v-icon><br>Bù hǎo.</h3>
            <!-- <h2 v-if="this.cardfound == null" style="color: #fffaf0;">。<v-icon large color="grey">mdi-emoticon-happy</v-icon><br>.</h2> -->
          </div>
      <v-col cols="12" lg="9" class="text-center" :class="$isMobile() ? 'room2' : ''">
        <v-row :class="$isMobile() ? '' : 'room'" class="text-center mt-0 mt-lg-15 pa-0 mx-0" justify="center" :key="componentKey">
          <v-col
            v-for="(card, i) in this.shuffledCards"
            :key="i"
            :href="card.href"
            class="pa-0 ma-0"
            cols="4" lg="4"
            v-on:click="checkSet(i)"
          >
            <SearchObject :msg="card" :id="i" :correctCard="randomIndex"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import MemoryCard from '../components/MemoryCard';
import SearchObject from '../components/SearchObject';
import SoundCard from '../components/SoundCard';
import PopUp from '../components/PopUp';
import PopUpReturn from '../components/PopUpReturn';
import store from '../store'

export default {
  name: 'MemoryCanvas',
  components: {
    // MemoryCard,
    SearchObject,
    SoundCard,
    PopUp,
    PopUpReturn,
  },
  created: function () {
    this.randomCards()
  },
  methods: {
    // reloadPage(){
    //     window.location.reload()
    // },
    checkSet(id) {
      if (this.disableButton === true){
        this.clickedCards += 1
        this.disableButton = false
        if (this.randomIndex == id){
            this.cardfound = true
            this.points += 8;
            this.showCorrect(id, true, true)
        } else {
            this.cardfound = false
            this.showCorrect(id, this.cardfound, true)
            // this.points -= 1;
        }
        this.checkScore()
      } 
      // else {
      //   console.log("nothing")
      // }
    },
    checkScore(){
      if(this.clickedCards >= 5){
        // this.gameOver = true
        this.gameOver()
      }
    },
    startAgain(){
      // this.gameOver = false
      this.points = 0
      this.clickedCards = 0
      this.randomCards()
    },
    randomCards(){
      this.disableButton = true
      let array = this.cards
      let currentIndex = array.length, temporaryValue, randomIndex;
      this.showCorrect(null, false, false)
      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }
      this.shuffledCards = array
      // console.log(this.shuffledCards)
      this.componentKey += 1;
      this.cardfound = null;
      this.makeRandomIndex()
    },
    makeRandomIndex() {
      let array = this.cards.length
      this.randomIndex = Math.floor(Math.random() * array);
    },
    showCorrect(id, correct, clicked) {
      store.commit('SHOWCORRECT', { correct, id, clicked});
    },
    gameOver() {
      store.commit('GAMEOVER', true);
    }
  },
  computed: {
    // RandomIndex() {
    //   let array = this.cards.length
    //   let randomIndex = Math.floor(Math.random() * array);
    //   return randomIndex;
    // }
  },
  data: () => ({
    cardfound: null,
    message: "Click on the blue button. Match the sound to the picture.",
    image: "assets/images/blue-button.png",
    shuffledCards: null,
    componentKey: 0,
    points: 0,
    randomIndex: null,
    // gameOver: false,
    clickedCards: 0,
    disableButton: true,
    cards: [
      {
        id: '1',
        img: 'https://media.istockphoto.com/vectors/cute-fish-character-cartoon-vector-illustration-vector-id1045561036?b=1&k=6&m=1045561036&s=612x612&w=0&h=g3o38iDSBk1n-_ak0orDkS2FoPo9U7HvyObIGtQQeWg=',
        sound: "assets/sentences2/yu-fish.mp3",
        show: false,
      },
      {
        id: '2',
        img: 'https://media.istockphoto.com/vectors/green-tea-in-cup-and-green-leaves-vector-illustration-in-flat-design-vector-id1157013982?b=1&k=6&m=1157013982&s=612x612&w=0&h=Acn8wqQt17FDf3uUkQJnpuC6gpp3-tYDAkqI5bHAJzA=',
        sound: "assets/sentences2/cha-tea.mp3",
        show: false,
      },
      {
        id: '3',
        img: 'https://media.istockphoto.com/vectors/rice-bowl-vector-id547231286?b=1&k=6&m=547231286&s=612x612&w=0&h=oG90dozDg7BKAA-XS74gMyD2PuesQnr6tEwR-WRSicw=',
        sound: "assets/sentences2/fan.mp3",
        show: false,
      },
      {
        id: '4',
        img: 'https://img.freepik.com/free-vector/glass-water-icon_68196-604.jpg?size=338&ext=jpg',
        sound: "assets/sentences2/shui-water.mp3",
        show: false,
      },
      {
        id: '5',
        img: 'https://image.freepik.com/vrije-vector/aziatische-traditionele-gerechten-met-noedels-en-eetstokjes_29190-4960.jpg',
        sound: "assets/sentences2/mian.mp3",
        show: false,
      },
      {
        id: '6',
        img: 'https://whitehorsenetball.org.au/wp-content/uploads/2019/03/weather-2.jpg',
        sound: "assets/sentences2/ri-hot.mp3",
        show: false,
      },
    ],
  }),
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.room {
  background-image: 
  linear-gradient(to bottom, rgba(255, 255, 255, 0.103), rgba(255, 255, 255, 0.082), rgba(255, 255, 255, 0.089)), 
  url("../assets/images/room8.jpeg");
  // height: 600px;
//   width: 100%;
  background-size: contain;
  background-position: center center;
}
.room2 {
  background-image: 
  linear-gradient(to bottom, rgba(255, 255, 255, 0.103), rgba(255, 255, 255, 0.082), rgba(255, 255, 255, 0.089)), 
  url("../assets/images/room8.jpeg");
  // height: 700px;
//   width: 100%;
  background-size: cover;
  padding: 20px 0 20px 0;
  // background-color: black;
  // background-position: center center;
}
</style>
