<template>
  <div>
    <v-card v-if="!checked" class="answer-button my-10"  dark :color="button == item ? 'red accent-2' : 'red accent-1'" @click="playSound()">
        <!-- <div v-if="this.songPaused">{{this.item.pinyin}}</div><div v-else>Stop.</div> <v-icon class="pl-1">mdi-music</v-icon> -->
        <!-- <h4 v-if="button == item">{{this.item.correct}}  - </h4> -->
        <!-- <h4 v-if="checked" >{{item.correct}}</h4> -->
        <v-row class="text-center justify-center">
          <v-col>
            <h3 class="pt-1">{{this.item.pinyin}}</h3>
          </v-col>
        </v-row>
        <audio ref="audioElm" :src="require('@/' + this.item.audio)"></audio>
    </v-card>
    <v-card v-if="checked" :style="button !== this.item ? 'opacity: 0.4' : 'opacity: 1'" class="answer-button my-10" elevation="0" dark :color="item.correct ? 'green lighten-3' : 'red accent-1'" >
      <!-- <div v-if="this.songPaused">{{this.item.pinyin}}</div><div v-else>Stop.</div> <v-icon class="pl-1">mdi-music</v-icon> -->
      <!-- <h4 v-if="button == item">{{this.item.correct}}  - </h4> -->
      <!-- <h4 v-if="checked" >{{item.correct}}</h4> -->
      <v-row class="text-center justify-center">
        <v-col>
          <h3 class="pt-1">{{this.item.pinyin}}</h3>
        </v-col>
        <!-- <v-col> -->
          <!-- <v-icon class="pr-lg-10 pr-0 pa-0">mdi-volume-high</v-icon> -->
        <!-- </v-col> -->
        <v-col v-if="button == this.item">
          <v-icon v-if="item.correct" large color="light-green" class="pl-2">mdi-check</v-icon>
          <v-icon v-else large color="red darken-4" class="pl-2">mdi-close</v-icon>
        </v-col>
      </v-row>
      <audio ref="audioElm" :src="require('@/' + this.item.audio)"></audio>
    </v-card>
  </div>
</template>

<script>
import store from '../store'

  export default {
    name: 'SongListItem',
    data: () => ({
      songPaused: true,
      // checked: false,
    }),
    props: {
      item: Object,
    },
    computed: {
      button () {
        return store.state.button
      },
      checked(){
        let checked = store.state.checked
        return checked
      }
    },
    methods: {
      playSound () {
        // this.active = true
        if (this.$refs.audioElm.paused){
          this.$refs.audioElm.play();
          this.songPaused = this.$refs.audioElm.paused;
          // :color="this.active ? 'light-green' : 'green'"
        } else {
          this.$refs.audioElm.pause();
          this.$refs.audioElm.currentTime = 0;
          this.songPaused = this.$refs.audioElm.paused;
        }
      },
    }
  }
</script>