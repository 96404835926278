<template>
  <v-container>
    <!-- <router-link class="green--text" to="/games"><h2>Back</h2></router-link> -->
    <pop-up-achieve :msg="this.message" :img="this.image"/>
    <v-row class="text-left mt-lg-15 ml-lg-5 mr-lg-5">
      <v-col cols="12" class="col-lg-6 pr-lg-15 pt-lg-10 px-10">
        <v-img
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Store_Building_Flat_Icon_Vector.svg/1024px-Store_Building_Flat_Icon_Vector.svg.png"
            style="cursor: pointer;"
            :height="$isMobile() ? 150 : '' "
            @click="playSound()"
        />
      </v-col>
      <v-col cols="12" class="col-lg-6 pt-0 mt-0 mt-lg-12">
          <h1 class="red--text text--darken-4">Shop</h1>
          <v-card class="rounded-xl pt-3 pb-2 pt-lg-5 pb-lg-0 px-lg-10 px-6 mx-7 my-2 ma-lg-5" :disabled="totalScore < 10">
              <v-row class="justify-center text-center">
                <v-col cols="6" lg="5" class="justify-center text-left ">
                    <p class="mt-2">
                        Topography
                    </p>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="text-center mt-1" cols="5" lg="3">
                    <v-btn
                        v-if="!bought.topography"
                        color="red"
                        dark
                        @click="deductScore(10, 'topography')"
                        >
                        &#165;10
                    </v-btn>
                    <h4 v-else class="py-1 rounded-xl green lighten-3 white--text"> OPEN </h4>
                </v-col>
            </v-row>
          </v-card>
          <v-card class="rounded-xl pt-3 pb-2 pt-lg-5 pb-lg-0 px-lg-10 px-6 mx-7 my-2 ma-lg-5" :disabled="totalScore < 20">
              <v-row class="justify-center text-center">
                <v-col cols="6" lg="5" class="justify-center text-left">
                    <p class="mt-2">
                        Search Game
                    </p>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="text-center mt-1" cols="5" lg="3">
                    <v-btn
                        v-if="!bought.searchgame"
                        color="red"
                        dark
                        @click="deductScore(20, 'searchgame')"
                        >
                        &#165;20
                    </v-btn>
                    <h4 v-else class="py-1 rounded-xl green lighten-3 white--text"> OPEN </h4>
                </v-col>
            </v-row>
          </v-card>
          <v-card class="rounded-xl pt-3 pb-2 pt-lg-5 pb-lg-0 px-lg-10 px-6 mx-7 my-2 ma-lg-5" :disabled="totalScore < 30">
              <v-row class="justify-center text-center">
                <v-col cols="6" lg="5" class="justify-center text-left ">
                    <p class="mt-2">
                        Characters
                    </p>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="text-center mt-1" cols="5" lg="3">
                    <v-btn
                        v-if="!bought.characters"
                        color="red"
                        dark
                        @click="deductScore(30, 'characters')"
                        >
                        &#165;30
                    </v-btn>
                    <h4 v-else class="py-1 rounded-xl green lighten-3 white--text"> OPEN </h4>
                </v-col>
            </v-row>
          </v-card>
          <v-card class="rounded-xl pt-3 pb-2 pt-lg-5 pb-lg-0 px-lg-10 px-6 mx-7 my-2 ma-lg-5" :disabled="totalScore < 40">
              <v-row class="justify-center text-center">
                <v-col cols="6" lg="5" class="justify-center text-left ">
                    <p class="mt-2">
                      HSK 3
                    </p>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="text-center mt-1" cols="5" lg="3">
                    <v-btn
                        v-if="!bought.hsk"
                        color="red"
                        dark
                        @click="deductScore(40, 'popup')"
                        >
                        &#165;40
                    </v-btn>
                    <h4 v-else class="py-1 rounded-xl green lighten-3 white--text"> OPEN </h4>
                </v-col>
            </v-row>
          </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PopUpAchieve from '../components/PopUpAchieve'
import store from '../store'

export default {
  components: {
    PopUpAchieve,
  },
  created: function () {
    store.commit('GAMEOVER', false);
  },
//   computed: {
//     gameOver () {
//       console.log(store.state.gameOver)
//       return store.state.gameOver
//     },
//   },
  data () {
    return {
      message: "You finished HSK 1 and HSK 2!",
    image: "assets/images/party.jpeg", 
    }
  },
  computed:{
    totalScore() {
      return store.state.totalScore
    },
    bought(){
      return store.state.openGame
    }
  },
  methods: {
    openGame(open){
        store.commit('OPENGAME', open);
        console.log(open)
    },
    deductScore(score, open) {
      this.openGame(open)
      store.commit('DEDUCTSCORE', score);
    },
  }
}
</script>
