<template>
  <v-container>
    <!-- <router-link class="green--text" to="/talking"><h2>Back</h2></router-link> -->
      <h2>Try to respond to the questions out loud.</h2>
      <p>Click on the sentences to see what they mean.</p>
      <pop-up :msg="this.message" :img="this.image"/>
    <v-row>
      <v-col cols="12" lg="6" align="center">
        <v-row>
          <v-col cols="12" v-if="!this.againButton">
          <!-- <img alt="Vue logo" 
            class="my-3"
            contain
            height="200"
            src="../assets/images/talk-cloud.png"> -->
            <v-chip
              class="ma-2 mt-lg-15"
              color="success"
              outlined
              label
              x-large
              v-on:click="changeLanguage()"
            >
              <!-- <v-icon left>mdi-server-plus</v-icon> -->
              <v-row v-if="this.flip">
                <v-col cols="12" class="pb-0 my-0 pt-5">
                  <h2>{{ this.levels.items[this.number].conversation[this.counter].cnc}} <v-icon class="pl-0">mdi-comment-question-outline</v-icon></h2>
                </v-col>
                <v-col cols="12" class="pb-0 my-0 pt-2">
                  <p v-if="this.flip">{{ this.levels.items[this.number].conversation[this.counter].cn}}</p>
                </v-col>
              </v-row>
              <h2 v-else>{{ this.levels.items[this.number].conversation[this.counter].en}}</h2>
            </v-chip>
          </v-col>
          <v-col cols="12" v-else>
            <h1 class="ma-0 mt-lg-15 green--text" >Start again</h1>
            <h3>Click the button.</h3>
          </v-col>
          <v-col v-if="!$isMobile()" cols="12" class="mt-15">
            <v-btn dark fab x-large color="green" v-on:click="click()">
              <!-- <v-icon class="pl-0" x-large>mdi-microphone</v-icon> -->
              <v-icon class="pl-0" x-large>mdi-skip-next</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="6" align="center">
        <img alt="Talking people" 
          v-if="$isMobile()"
          class="my-3"
          contain
          height="150"
          src="../assets/images/talking.gif">
        <img alt="Talking people" 
          v-else
          class="my-3"
          contain
          height="350"
          src="../assets/images/talking.gif">
      </v-col>
      <v-col v-if="$isMobile()" cols="12" lg="6" align="center">
        <v-btn  dark fab x-large color="green" v-on:click="click()">
          <!-- <v-icon class="pl-0" x-large>mdi-microphone</v-icon> -->
          <v-icon class="pl-0" x-large>mdi-skip-next</v-icon>
        </v-btn>
        <!-- <audio ref="audioElm" :src="require('@/' + this.levels.items[this.number].conversation[this.counter].voice)"></audio> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Levels from '@/assets/json/levels.json'
import PopUp from '../components/PopUp';

export default {
  name: 'TalkPage',
  components: {
    PopUp,
  },
  data () {
    return {
        levels: Levels,
        number: this.$route.params.id - 1,
        counter: 0,
        flip: true,
        playlist: [],
        againButton: false,
        message: "Try to answer the questions out loud.",
        image: "assets/images/talking-expl.png",
    }
  },
  mounted() {
    this.levels.items[this.number].conversation.forEach(element => {
      let music = new Audio(require('@/' + element.voice));
      this.playlist.push(music);
    })
    this.playSound();
  },
  methods: {
      click() {
        if (this.counter >= (this.levels.items[this.number].conversation.length) ){
          // this.$refs.audioElm.pause();
          // this.$refs.audioElm.currentTime = 0;
          this.counter = 0;
          this.playSound();
          this.flip = true;
          this.againButton = false;
          // console.log("if");
          // console.log(this.counter);
          // console.log(this.levels.items[this.number].conversation.length)
        } else if (this.counter >= (this.levels.items[this.number].conversation.length - 1)) {
          this.againButton = true;
          this.counter += 1;
        } else {
          // this.$refs.audioElm.pause();
          // this.$refs.audioElm.currentTime = 0;
          this.counter += 1;
          this.playSound()
          this.flip = true;
          // console.log("else");
          // console.log(this.counter);
          // console.log(this.levels.items[this.number].conversation.length)
        }
      },
      playSound () {
        this.playlist[this.counter].play();
      },
      changeLanguage(){
        if (this.flip === true){
          this.flip = false;
        } else {
          this.flip = true;
        }
      }
    }
}
</script>