<template>
  <v-row justify="center">
    <v-dialog
      v-model="popUp"
      persistent
      max-width="600"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
        </v-btn>
      </template> -->
      <v-card class="rounded-xl orange lighten-5">
        <v-card-title class="headline white--text red lighten-2 mb-10">
          <h3>Congratulations!</h3>
        </v-card-title>
        <v-card-text class="text-center grey--text text--darken-3"><h2>{{this.msg}}</h2></v-card-text>
        <v-card-text><v-img class="rounded-xl" elevation="12" style="border: 5px solid red; opacity:0.5;" :src="require('@/' + this.img)"/></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Disagree
          </v-btn> -->
          <v-btn
            color="red darken-1"
            text
            class="rounded-xl"
            @click="dialog = popupAway()"
          >
            Thank you!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import store from '../store'

export default {
  computed: {
    popUp () {
      return store.state.openGame.popup
      },
    },
    props: {
      msg: String,
      img: String,
    },
    methods: {
      popupAway() {
        store.commit('OPENGAME', 'popup');
      },
    }
  }
</script>