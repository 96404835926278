<template>
  <div>
    <!-- <v-btn v-if="checked" class="answer-button" width="300" dark :color="item.correct === true ? 'green' : 'red'" @click="playSound()">
      <h3 >{{this.item.pinyin}}</h3> 
      <v-icon class="pl-1">mdi-volume-high</v-icon> -->
      <!-- <v-icon :color="item.correct === true ? 'green' : 'red'" class="pl-1">mdi-check</v-icon> -->
      <!-- <audio ref="audioElm" :src="require('@/' + this.item.audio)"></audio>
    </v-btn> -->
    <v-btn v-if="!checked" class="answer-button" width="300" dark :color="button == item ? 'red accent-2' : 'red accent-1'" @click="playSound()">
        <!-- <div v-if="this.songPaused">{{this.item.pinyin}}</div><div v-else>Stop.</div> <v-icon class="pl-1">mdi-music</v-icon> -->
        <!-- <h4 v-if="button == item">{{this.item.correct}}  - </h4> -->
        <!-- <h4 v-if="checked" >{{item.correct}}</h4> -->
        <h4 v-if="checked" :style="item.correct ? 'color: green;' : 'color : red;'">{{this.item.pinyin}}</h4> 

        <h4 v-else>{{this.item.pinyin}}</h4> 
        <v-icon class="pl-1">mdi-volume-high</v-icon>
        <audio ref="audioElm" :src="require('@/' + this.item.audio)"></audio>
    </v-btn>

    <v-btn v-if="checked" :style="button !== this.item ? 'opacity: 0.4' : 'opacity: 1'" class="answer-button white--text" width="300" :color="item.correct ? 'green lighten-4' : 'red accent-1'" @click="playSound()">
        <!-- <div v-if="this.songPaused">{{this.item.pinyin}}</div><div v-else>Stop.</div> <v-icon class="pl-1">mdi-music</v-icon> -->
        <!-- <h4 v-if="button == item">{{this.item.correct}}  - </h4> -->
        <!-- <h4 v-if="checked" >{{item.correct}}</h4> -->
        <h4 v-if="checked" :style="item.correct ? 'color: green;' : 'color : red;'">{{this.item.pinyin}}</h4> 

        <h4 v-else>{{this.item.pinyin}}</h4> 
        <!-- <v-icon class="pl-1">mdi-volume-high</v-icon> -->
        <div v-if="button == this.item">
          <v-icon v-if="item.correct" large color="light-green" class="pl-1">mdi-check</v-icon>
          <!-- <v-icon v-else large color="red darken-4" class="pl-1">mdi-heart-broken</v-icon> -->
          <!-- <v-icon v-else large color="red darken-4" class="pl-1">mdi-window-close</v-icon> -->
          <v-icon v-else large color="red darken-4" class="pl-1">mdi-close</v-icon>
          <!-- <v-icon v-else large color="red darken-4" class="pl-1">mdi-close-outline</v-icon> -->
        </div>
        <audio ref="audioElm" :src="require('@/' + this.item.audio)"></audio>
    </v-btn>

  </div>
</template>

<script>
import store from '../store'

  export default {
    name: 'SongListItem',
    data: () => ({
      songPaused: true,
    }),
    props: {
      item: Object,
      // checked: Boolean,
    },
    computed: {
      button () {
        // console.log("buttonstate")
        // console.log(store.state.button)
        return store.state.button
      },
      checked(){
        let checked = store.state.checked
        return checked
      }
    },
    methods: {
      playSound () {
        // this.active = true
        if (this.$refs.audioElm.paused){
          this.$refs.audioElm.play();
          this.songPaused = this.$refs.audioElm.paused;
          // :color="this.active ? 'light-green' : 'green'"
        } else {
          this.$refs.audioElm.pause();
          this.$refs.audioElm.currentTime = 0;
          this.songPaused = this.$refs.audioElm.paused;
        }
      },
    }
  }
</script>