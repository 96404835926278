<template>
  <v-container>
    <!-- <router-link class="green--text" to="/"><h2>Back</h2></router-link> -->
    <v-row class="text-center justify-center">
      <!-- <v-col cols="4">
        <h1 class="mt-3">Games</h1>
      </v-col> -->
      <!-- <v-col v-if="!$isMobile()" cols="8"></v-col> -->
      <v-col
        class="d-flex mt-5 mb-0 pb-0 title"
        cols="12"
        sm="3"
      >
        <v-select
          class="style-chooser"
          style="font-size: 30px; font-weight: bold;"
          v-model="selectItem"
          :items="items"
          item-text="name"
          item-value="number"
          @input="levelChoose(selectItem)"
          color="black"
          item-color="red"
          label="select"
          return-object
          single-line
        ></v-select>
      </v-col>
    </v-row>
    
    <!-- <v-row class="text-center" v-if="$isMobile()">
      <v-col cols="12" class="col-lg-4"><h3 :class="this.apple" class="amber--text text--lighten-4 mx-15 rounded-xl" style="cursor: pointer;" v-on:click="levelChoose('1')">Numbers</h3></v-col>
      <v-col cols="12" class="col-lg-4"><h3 :class="this.pear" class="amber--text text--lighten-4 mx-15 rounded-xl" style="cursor: pointer;" v-on:click="levelChoose('2')">Restaurant</h3></v-col>
      <v-col cols="12" class="col-lg-4"><h3 :class="this.banana" class="amber--text text--lighten-4 mx-15 rounded-xl" style="cursor: pointer;" v-on:click="levelChoose('3')">Shopping</h3></v-col>
    </v-row>
    <v-row class="text-center" v-else>
      <v-col cols="12" class="col-lg-4"><h2 :class="this.apple" class="amber--text text--lighten-4 mx-15 rounded-xl" style="cursor: pointer;" v-on:click="levelChoose('1')">Numbers</h2></v-col>
      <v-col cols="12" class="col-lg-4"><h2 :class="this.pear" class="amber--text text--lighten-4 mx-15 rounded-xl" style="cursor: pointer;" v-on:click="levelChoose('2')">Restaurant</h2></v-col>
      <v-col cols="12" class="col-lg-4"><h2 :class="this.banana" class="amber--text text--lighten-4 mx-15 rounded-xl" style="cursor: pointer;" v-on:click="levelChoose('3')">Shopping</h2></v-col>
    </v-row> -->
    <!-- <v-row  class="text-center"><v-col><h1>Level {{level}}</h1></v-col></v-row> -->
    <v-row v-if="level == 0 && hsk == 1" align="center" justify="center" class="mb-lg-15 mt-1 pb-lg-15" style="height:180px">
      <v-col cols="6" lg="4" align="center" justify="center" class="mb-0 pa-1 pa-lg-5">
        <v-hover v-slot="{ hover }">
          <v-card outlined :elevation="hover ? 1 : 0" class="pa-lg-4 pa-1 rounded-xl" to="/memory">
              <v-img
                v-if="$isMobile()"
                alt="Memory"
                contain
                src="../assets/images/level1.png"
                transition="scale-transition"
                height="100"
              />
              <v-img
                v-else
                alt="Memory"
                contain
                src="../assets/images/level1.png"
                transition="scale-transition"
                height="150"
              />
              <b class="red--text">Memory Numbers</b>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="6" lg="4" align="center" justify="center" class="mb-0 pa-1 pa-lg-5">
        <v-hover v-slot="{ hover }">
          <v-card outlined :elevation="hover ? 1 : 0" class="pa-lg-4 pa-1 rounded-xl" to="/memory2">
              <v-img
                v-if="$isMobile()"
                alt="Memory2"
                contain
                src="../assets/images/numbers4.png"
                transition="scale-transition"
                height="100"
              />
              <v-img
                v-else
                alt="Memory2"
                contain
                src="../assets/images/numbers4.png"
                transition="scale-transition"
                height="150"
              />
              <b class="red--text">Memory Reading</b>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-row v-if="level == 0 && hsk == 1" align="center" justify="center" class="mt-0 mt-sm-15 mt-lg-15 pt-lg-15 pt-0" style="height:180px">
      <v-col cols="6" lg="4" align="center" justify="center" class="mt-0 pa-1 pa-lg-5">
        <v-hover v-slot="{ hover }">
          <v-card outlined :elevation="hover ? 1 : 0" class="pa-lg-4 pa-1 rounded-xl" to="/memory3">
              <v-img
                v-if="$isMobile()"
                alt="Talking"
                contain
                src="../assets/images/numbers3.png"
                transition="scale-transition"
                height="100"
              />
              <v-img
                v-else
                alt="Talking"
                contain
                src="../assets/images/numbers3.png"
                transition="scale-transition"
                height="150"
              />
              <b class="red--text">Memory Listen</b>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="6" lg="4" align="center" justify="center" class="mt-0 pa-1 pa-lg-5">
        <v-hover v-slot="{ hover }">
          <v-card outlined :elevation="hover ? 1 : 0" class="pa-lg-4 pa-1 rounded-xl" to="/land" :disabled="!openGame.topography">
            <h3 v-if="!openGame.topography" class="white--text text--lighten-4 rounded-xl red darken-2" style="position: absolute; z-index: 3;" :style="$isMobile() ? 'margin-top: -15px; width:150px;' : 'margin-top: -30px; width:200px; font-size: 25px'">Unlock in shop</h3>
              <v-img
                v-if="$isMobile()"
                alt="Talking"
                contain
                src="../assets/images/shanghai.gif"
                transition="scale-transition"
                height="100"
              />
              <v-img
                v-else
                alt="Talking"
                contain
                src="../assets/images/shanghai.gif"
                transition="scale-transition"
                height="150"
              />
              <b class="red--text">China Topography</b>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-row v-if="level == 0 && hsk == 2" align="center" justify="center" class="mb-lg-15 mt-1 pb-lg-15" style="height:180px">
      <v-col cols="6" lg="4" align="center" justify="center" class="mb-0 pa-1 pa-lg-5">
        <v-hover v-slot="{ hover }">
          <v-card outlined :elevation="hover ? 1 : 0" class="pa-lg-4 pa-1 rounded-xl" to="/memory">
              <v-img
                v-if="$isMobile()"
                alt="Memory"
                contain
                src="../assets/images/level3.png"
                transition="scale-transition"
                height="100"
              />
              <v-img
                v-else
                alt="Memory"
                contain
                src="../assets/images/level3.png"
                transition="scale-transition"
                height="150"
              />
              <b class="red--text">Memory Outside</b>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="6" lg="4" align="center" justify="center" class="mb-0 pa-1 pa-lg-5">
        <v-hover v-slot="{ hover }">
          <v-card outlined :elevation="hover ? 1 : 0" class="pa-lg-4 pa-1 rounded-xl" to="/memory2">
              <v-img
                v-if="$isMobile()"
                alt="Memory2"
                contain
                src="../assets/images/shopping1.png"
                transition="scale-transition"
                height="100"
              />
              <v-img
                v-else
                alt="Memory2"
                contain
                src="../assets/images/shopping1.png"
                transition="scale-transition"
                height="150"
              />
              <b class="red--text">Memory Reading</b>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-row v-if="level == 0 && hsk == 2" align="center" justify="center" class="mt-0 mt-sm-15 mt-lg-15 pt-lg-15 pt-0" style="height:180px">
      <v-col cols="6" lg="4" align="center" justify="center" class="mt-0 pa-1 pa-lg-5">
        <v-hover v-slot="{ hover }">
          <v-card outlined :elevation="hover ? 1 : 0" class="pa-lg-4 pa-1 rounded-xl" to="/memory3">
              <v-img
                v-if="$isMobile()"
                alt="Talking"
                contain
                src="../assets/images/shopping2.png"
                transition="scale-transition"
                height="100"
              />
              <v-img
                v-else
                alt="Talking"
                contain
                src="../assets/images/shopping2.png"
                transition="scale-transition"
                height="150"
              />
              <b class="red--text">Memory Listen</b>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="6" lg="4" align="center" justify="center" class="mt-0 pa-1 pa-lg-5">
        <v-hover v-slot="{ hover }">
          <v-card outlined :elevation="hover ? 1 : 0" class="pa-lg-4 pa-1 rounded-xl" to="/write" :disabled="!openGame.characters">
            <h3 v-if="!openGame.characters" class="white--text text--lighten-4 rounded-xl red darken-2" style="position: absolute; z-index: 3;" :style="$isMobile() ? 'margin-top: -15px; width:150px;' : 'margin-top: -30px; width:200px; font-size: 25px'">Unlock in shop</h3>
              <v-img
                v-if="$isMobile()"
                alt="Talking"
                contain
                src="../assets/images/water.png"
                transition="scale-transition"
                height="100"
              />
              <v-img
                v-else
                alt="Talking"
                contain
                src="../assets/images/water.png"
                transition="scale-transition"
                height="150"
              />
              <b class="red--text">Characters</b>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-row v-if="level == 1 && hsk == 1" align="center" justify="center" class="mb-lg-15 mb-sm-15 mb-0 mt-1 pb-lg-15" style="height:180px">
        <v-col cols="6" lg="4" align="center" justify="center" class="mb-0 pa-1 pa-lg-5">
          <v-hover v-slot="{ hover }">
            <v-card outlined :elevation="hover ? 1 : 0" class="pa-lg-4 pa-1 rounded-xl" to="/memory">
                <v-img
                  v-if="$isMobile()"
                  alt="Memory"
                  contain
                  src="../assets/images/memory1d.png"
                  transition="scale-transition"
                  height="100"
                />
                <v-img
                  v-else
                  alt="Memory"
                  contain
                  src="../assets/images/memory1c.png"
                  transition="scale-transition"
                  height="150"
                />
                <b class="red--text">Memory Image</b>
            </v-card>
          </v-hover>
        </v-col>
        <v-col cols="6" lg="4" align="center" justify="center" class="mb-0 pa-1 pa-lg-5">
          <v-hover v-slot="{ hover }">
            <v-card outlined :elevation="hover ? 1 : 0" class="pa-lg-4 pa-1 rounded-xl" to="/memory2">
                <v-img
                  v-if="$isMobile()"
                  alt="Memory2"
                  contain
                  src="../assets/images/memory2d.png"
                  transition="scale-transition"
                  height="100"
                />
                <v-img
                  v-else
                  alt="Memory2"
                  contain
                  src="../assets/images/memory2c.png"
                  transition="scale-transition"
                  height="150"
                />
                <b class="red--text">Memory Reading</b>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <v-row v-if="level == 1 && hsk == 1" align="center" justify="center" class="mt-0 mt-sm-15 mt-lg-15 pt-lg-15 pt-0" style="height:180px">
        <v-col cols="6" lg="4" align="center" justify="center" class="mt-0 pa-1 pa-lg-5">
          <v-hover v-slot="{ hover }">
            <v-card outlined :elevation="hover ? 1 : 0" class="pa-lg-4 pa-1 rounded-xl" to="/memory3">
                <v-img
                  v-if="$isMobile()"
                  alt="Talking"
                  contain
                  src="../assets/images/memory3d.png"
                  transition="scale-transition"
                  height="100"
                />
                <v-img
                  v-else
                  alt="Talking"
                  contain
                  src="../assets/images/memory3c.png"
                  transition="scale-transition"
                  height="150"
                />
                <b class="red--text">Memory Listen</b>
            </v-card>
          </v-hover>
        </v-col>
        <v-col cols="6" lg="4" align="center" justify="center" class="mb-0 pa-1 pa-lg-5">
          <v-hover v-slot="{ hover }">
            <v-card outlined :elevation="hover ? 1 : 0" class="pa-lg-4 pa-1 rounded-xl" to="/searchgame" :disabled="!openGame.searchgame">
              <h3 v-if="!openGame.searchgame" class="white--text text--lighten-4 rounded-xl red darken-2" style="position: absolute; z-index: 3;" :style="$isMobile() ? 'margin-top: -15px; width:150px;' : 'margin-top: -30px; width:200px; font-size: 25px'">Unlock in shop</h3>
                <v-img
                  v-if="$isMobile()"
                  alt="SearchGame"
                  contain
                  src="../assets/images/search-game.jpeg"
                  transition="scale-transition"
                  height="100"
                />
                <v-img
                  v-else
                  alt="Memory2"
                  contain
                  src="../assets/images/search-game.jpeg"
                  transition="scale-transition"
                  height="150"
                />
                <b class="red--text">Search Game</b>
            </v-card>
            </v-hover>
        </v-col>
        
        <!-- <v-col cols="6" sm="8" lg="4" align="center" justify="center" class="mt-0 pa-1 pa-lg-5">
          <v-card outlined class="pa-lg-4 pa-1" to="/stories">
              <v-img
                alt="Stories"
                class="mr-2"
                contain
                src="https://image.freepik.com/free-vector/kids-story-book_1051-553.jpg"
                transition="scale-transition"
              />
              Flashcards
          </v-card>
        </v-col> -->
    </v-row>
    <v-row>
      <!-- {{this.items[0]}} <br> {{this.select}} -->
      <!-- {{this.selectItem}} -->
    </v-row>
  </v-container>
</template>

<script>
  // import HelloWorld from '../components/HelloWorld'
  import store from '../store'

  export default {
    created() {
      // console.log(this.$isMobile())
      this.levelChoose(this.items[this.level])
      this.selectItem = this.items[this.level]
    },
    name: 'Home',

    components: {
      // HelloWorld,
    },
    data () {
      return {
        selectItem: {},
        // select: {name: 'Numbers', number: '1'},
        // items: { hsk1 : [{name: 'Numbers', number: '1'}, {name: 'Restaurant', number: '2'}],
        //          hsk2 : [{name: 'Restaurant', number: '2'}]},
        // ding: 'items.' + this.hsk,
      }
    },
    watch: {
      hsk () {
        this.selectItem = this.items[this.level]
      },
    },
    computed: {
      level () {
        return store.state.level
      },
      openGame () {
        return store.state.openGame
      },
      hsk () {
        return store.state.hsk
      },
      items () {
        var it
        if (store.state.hsk == 1){
          it = [{name: 'Numbers', number: 0}, {name: 'Restaurant', number: 1}]
        } else{
          it = [{name: 'Outside', number: 0}]
        }
        return it
      },
      // select () {
      //   console.log(this.items[this.level])
      //   console.log(store.state.select)
      //   return store.state.select
      // }
    },
    methods: {
      levelChoose(selectedLevel) {
        store.commit("LEVEL", selectedLevel.number);
        // store.commit("SELECT", selectedLevel);
        // console.log(JSON.parse(JSON.stringify(this.items[0])))

        // this.select = JSON.parse(JSON.stringify(this.items[0]))

        // if (selectedLevel === '1') {
        //   store.commit('LEVEL1');
          // this.apple = "red accent-1",
          // this.pear = "red",
          // this.banana = "red"
        // } else if (selectedLevel === '2')  {
        //   store.commit('LEVEL2');
          // this.apple = "red",
          // this.pear = "red accent-1",
          // this.banana = "red"
        // } else if (selectedLevel === '3')  {
        //   store.commit('LEVEL3');
          // this.apple = "red",
          // this.pear = "red",
          // this.banana = "red accent-1"
        // }
      }
    }
  }
</script>

<style lang="scss">
  .level{
    &.router-link-exact-active {
      color: #7a7d7e;;
    }
  }
  .v-select-list {
    background: #ffaaaa;
  }
  .title .v-select__selection.v-select__selection--comma{
    padding:5px;
    color: #7a7d7e;
  }
  .title .mdi-menu-down::before {
    content: "\F035D";
    padding-top: 15px;
    font-size: 30px;
  }
</style>