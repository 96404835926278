<template>
    <v-btn width="100" dark color="green" @click="playSound()">
        <!-- <div v-if="this.songPaused">{{this.item.pinyin}}</div><div v-else>Stop.</div> <v-icon class="pl-1">mdi-music</v-icon> -->
        <h3>{{this.item.pinyin}}</h3> <v-icon class="pl-1">mdi-volume-high</v-icon>
        <audio ref="audioElm" :src="require('@/' + this.item.audio)"></audio>
    </v-btn>
</template>

<script>
  export default {
    name: 'SongListItem',
    data: () => ({
      songPaused: true,
    }),
    props: {
      item: Object,
    },
    methods: {
      playSound () {
        if (this.$refs.audioElm.paused){
          this.$refs.audioElm.play();
          this.songPaused = this.$refs.audioElm.paused;
        } else {
          this.$refs.audioElm.pause();
          this.$refs.audioElm.currentTime = 0;
          this.songPaused = this.$refs.audioElm.paused;
        }
      }
    }
  }
</script>