<template>
  <v-container class="py-1 text-center">
    <v-row class="pl-5 pb-2">
        <v-col cols="5">
          <v-row>
            <h2 class="px-0 pt-5 black--text">{{this.msg.description.cn}}</h2>
          </v-row>
          <v-row>
            <p class="px-0 black--text">{{this.msg.description.en}}</p>
          </v-row>
        </v-col>
        <v-col class="pt-10">
          0:{{this.secondsLeft}} / 0:{{this.total}}
        </v-col>
        <v-col cols="5" class="text-center">
          <h3 class="px-0 black--text"><b>{{this.msg.level}}</b></h3>
            <v-btn dark color="red" @click="playSound()" class="red--text text--lighten-5">
              <div v-if="this.songPaused">Play</div><div v-else>Stop</div> <v-icon class="pl-1">mdi-music</v-icon>
            </v-btn>
            <!-- <audio ref="audioElm" src="@/assets/songs/level_1_chinese_song.mp3"></audio> -->
            <audio ref="audioElm" :src="require('@/' + this.msg.song)"></audio>
        </v-col>
    </v-row>
    <!-- <v-spacer></v-spacer> -->   
    <!-- <hr> -->
    <!-- </v-row> -->
  </v-container>
</template>

<script>
import store from '../store'
  export default {
    name: 'SongListItem',

    data: () => ({
      songPaused: true,
      secondsLeft: "00",
      total: "00",
      timeOut: null,
    }),
    props: {
      msg: Object,
    },
    mounted: function () {
      this.total = this.msg.duration
    },
    computed: {
      lang () {
        return store.state.language
      },
    },
    methods: {
      playSound () {
        if (this.$refs.audioElm.paused){
          // console.log(this.$refs.audioElm.duration)
          this.total = parseInt(this.$refs.audioElm.duration)
          this.$refs.audioElm.play();
          this.songPaused = this.$refs.audioElm.paused;
          // console.log("print1")
          this.countdown()
        } else {
          this.$refs.audioElm.pause();
          this.$refs.audioElm.currentTime = 0;
          this.songPaused = this.$refs.audioElm.paused;
          // this.secondsLeft = "00"
          this.countDownTimer(0)
        }
      },
      countdown(){
        let total = parseInt(this.$refs.audioElm.duration)
        this.countDownTimer(total)
      },
      countDownTimer(total){
        if(total > 0) {
          this.timeOut = setTimeout(() => {
            total -= 1
            if(total.toString().length < 2){
              this.secondsLeft= "0"+total;
            } else {
              this.secondsLeft = total
            }
            this.countDownTimer(total)
          }, 1000)
        } else {
          clearTimeout(this.timeOut)
          this.secondsLeft = "00"
          this.songPaused = true
          return;
        }
      }
    }
  }
</script>
