<template>
  <v-container>
    <!-- <router-link class="red--text" to="/stories"><h2 v-on:click="stopSound()">Back</h2></router-link> -->
    <v-row class="ml-lg-15 mt-lg-10">
        <v-col class="ml-lg-10">
            <h1 class="red--text">Rice and tea.</h1>
            <pop-up :msg="this.message" :img="this.image" />
            <v-row v-for="(story, i) in storyone.items" :key="i">
              <words :msg="story"/>
            </v-row>
            <v-row>
                <v-col cols="4" class="mb-0 ml-15">
                    <v-btn dark color="red" @click="playSound()">
                        <div v-if="this.songPaused">Play the story!</div><div v-else>Stop the story</div> <v-icon class="pl-1">mdi-music</v-icon>
                    </v-btn>
                    <audio ref="audioElm" :src="require('@/' + this.stories.items[0].sound)"></audio>
                </v-col>
            </v-row>
        </v-col>
        <v-col class="mr-lg-15">
          <v-img
            alt="Games"
            class="mr-2"
            contain
            src="https://static.vecteezy.com/system/resources/thumbnails/000/272/352/small_2x/poke-bowl.jpg"
            transition="scale-transition"
          
          />
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from '../store'
import StoryOne from '@/assets/json/storyone.json'
import Stories from '@/assets/json/stories.json'
import Words from '@/components/Words.vue'
import PopUp from '../components/PopUp';

  export default {
    name: 'SongListItem',
    components: {
        Words,
        PopUp,
    },
    data: () => ({
      songPaused: true,
      storyone: StoryOne,
      stories: Stories,
      message: "Click on the sentences to hear them seperately.",
      image: "assets/images/storyone.png",
    }),
    // props: {
    //   msg: Object,
    // },
    computed: {
      lang () {
        return store.state.language
      }
    },
    methods: {
      playSound () {
        if (this.$refs.audioElm.paused){
          this.$refs.audioElm.play();
          this.songPaused = this.$refs.audioElm.paused;
        } else {
          this.$refs.audioElm.pause();
          this.$refs.audioElm.currentTime = 0;
          this.songPaused = this.$refs.audioElm.paused;
        }
      },
      stopSound(){
        console.log("stop")
        this.$refs.audioElm.pause();
        this.$refs.audioElm.currentTime = 0;
      },
    }
  }
</script>