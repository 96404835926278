var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"text-center justify-center"},[_c('v-col',{staticClass:"d-flex mt-5 mb-0 pb-0 title",attrs:{"cols":"12","sm":"3"}},[_c('v-select',{staticClass:"style-chooser",staticStyle:{"font-size":"30px","font-weight":"bold"},attrs:{"items":_vm.items,"item-text":"name","item-value":"number","color":"black","item-color":"red","label":"select","return-object":"","single-line":""},on:{"input":function($event){return _vm.levelChoose(_vm.selectItem)}},model:{value:(_vm.selectItem),callback:function ($$v) {_vm.selectItem=$$v},expression:"selectItem"}})],1)],1),(_vm.level == 0 && _vm.hsk == 1)?_c('v-row',{staticClass:"mb-lg-15 mt-1 pb-lg-15",staticStyle:{"height":"180px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"mb-0 pa-1 pa-lg-5",attrs:{"cols":"6","lg":"4","align":"center","justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-lg-4 pa-1 rounded-xl",attrs:{"outlined":"","elevation":hover ? 1 : 0,"to":"/memory"}},[(_vm.$isMobile())?_c('v-img',{attrs:{"alt":"Memory","contain":"","src":require("../assets/images/level1.png"),"transition":"scale-transition","height":"100"}}):_c('v-img',{attrs:{"alt":"Memory","contain":"","src":require("../assets/images/level1.png"),"transition":"scale-transition","height":"150"}}),_c('b',{staticClass:"red--text"},[_vm._v("Memory Numbers")])],1)]}}],null,false,3432888416)})],1),_c('v-col',{staticClass:"mb-0 pa-1 pa-lg-5",attrs:{"cols":"6","lg":"4","align":"center","justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-lg-4 pa-1 rounded-xl",attrs:{"outlined":"","elevation":hover ? 1 : 0,"to":"/memory2"}},[(_vm.$isMobile())?_c('v-img',{attrs:{"alt":"Memory2","contain":"","src":require("../assets/images/numbers4.png"),"transition":"scale-transition","height":"100"}}):_c('v-img',{attrs:{"alt":"Memory2","contain":"","src":require("../assets/images/numbers4.png"),"transition":"scale-transition","height":"150"}}),_c('b',{staticClass:"red--text"},[_vm._v("Memory Reading")])],1)]}}],null,false,63565872)})],1)],1):_vm._e(),(_vm.level == 0 && _vm.hsk == 1)?_c('v-row',{staticClass:"mt-0 mt-sm-15 mt-lg-15 pt-lg-15 pt-0",staticStyle:{"height":"180px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"mt-0 pa-1 pa-lg-5",attrs:{"cols":"6","lg":"4","align":"center","justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-lg-4 pa-1 rounded-xl",attrs:{"outlined":"","elevation":hover ? 1 : 0,"to":"/memory3"}},[(_vm.$isMobile())?_c('v-img',{attrs:{"alt":"Talking","contain":"","src":require("../assets/images/numbers3.png"),"transition":"scale-transition","height":"100"}}):_c('v-img',{attrs:{"alt":"Talking","contain":"","src":require("../assets/images/numbers3.png"),"transition":"scale-transition","height":"150"}}),_c('b',{staticClass:"red--text"},[_vm._v("Memory Listen")])],1)]}}],null,false,782701674)})],1),_c('v-col',{staticClass:"mt-0 pa-1 pa-lg-5",attrs:{"cols":"6","lg":"4","align":"center","justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-lg-4 pa-1 rounded-xl",attrs:{"outlined":"","elevation":hover ? 1 : 0,"to":"/land","disabled":!_vm.openGame.topography}},[(!_vm.openGame.topography)?_c('h3',{staticClass:"white--text text--lighten-4 rounded-xl red darken-2",staticStyle:{"position":"absolute","z-index":"3"},style:(_vm.$isMobile() ? 'margin-top: -15px; width:150px;' : 'margin-top: -30px; width:200px; font-size: 25px')},[_vm._v("Unlock in shop")]):_vm._e(),(_vm.$isMobile())?_c('v-img',{attrs:{"alt":"Talking","contain":"","src":require("../assets/images/shanghai.gif"),"transition":"scale-transition","height":"100"}}):_c('v-img',{attrs:{"alt":"Talking","contain":"","src":require("../assets/images/shanghai.gif"),"transition":"scale-transition","height":"150"}}),_c('b',{staticClass:"red--text"},[_vm._v("China Topography")])],1)]}}],null,false,921990903)})],1)],1):_vm._e(),(_vm.level == 0 && _vm.hsk == 2)?_c('v-row',{staticClass:"mb-lg-15 mt-1 pb-lg-15",staticStyle:{"height":"180px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"mb-0 pa-1 pa-lg-5",attrs:{"cols":"6","lg":"4","align":"center","justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-lg-4 pa-1 rounded-xl",attrs:{"outlined":"","elevation":hover ? 1 : 0,"to":"/memory"}},[(_vm.$isMobile())?_c('v-img',{attrs:{"alt":"Memory","contain":"","src":require("../assets/images/level3.png"),"transition":"scale-transition","height":"100"}}):_c('v-img',{attrs:{"alt":"Memory","contain":"","src":require("../assets/images/level3.png"),"transition":"scale-transition","height":"150"}}),_c('b',{staticClass:"red--text"},[_vm._v("Memory Outside")])],1)]}}],null,false,397837413)})],1),_c('v-col',{staticClass:"mb-0 pa-1 pa-lg-5",attrs:{"cols":"6","lg":"4","align":"center","justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-lg-4 pa-1 rounded-xl",attrs:{"outlined":"","elevation":hover ? 1 : 0,"to":"/memory2"}},[(_vm.$isMobile())?_c('v-img',{attrs:{"alt":"Memory2","contain":"","src":require("../assets/images/shopping1.png"),"transition":"scale-transition","height":"100"}}):_c('v-img',{attrs:{"alt":"Memory2","contain":"","src":require("../assets/images/shopping1.png"),"transition":"scale-transition","height":"150"}}),_c('b',{staticClass:"red--text"},[_vm._v("Memory Reading")])],1)]}}],null,false,3483657040)})],1)],1):_vm._e(),(_vm.level == 0 && _vm.hsk == 2)?_c('v-row',{staticClass:"mt-0 mt-sm-15 mt-lg-15 pt-lg-15 pt-0",staticStyle:{"height":"180px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"mt-0 pa-1 pa-lg-5",attrs:{"cols":"6","lg":"4","align":"center","justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-lg-4 pa-1 rounded-xl",attrs:{"outlined":"","elevation":hover ? 1 : 0,"to":"/memory3"}},[(_vm.$isMobile())?_c('v-img',{attrs:{"alt":"Talking","contain":"","src":require("../assets/images/shopping2.png"),"transition":"scale-transition","height":"100"}}):_c('v-img',{attrs:{"alt":"Talking","contain":"","src":require("../assets/images/shopping2.png"),"transition":"scale-transition","height":"150"}}),_c('b',{staticClass:"red--text"},[_vm._v("Memory Listen")])],1)]}}],null,false,3031485770)})],1),_c('v-col',{staticClass:"mt-0 pa-1 pa-lg-5",attrs:{"cols":"6","lg":"4","align":"center","justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-lg-4 pa-1 rounded-xl",attrs:{"outlined":"","elevation":hover ? 1 : 0,"to":"/write","disabled":!_vm.openGame.characters}},[(!_vm.openGame.characters)?_c('h3',{staticClass:"white--text text--lighten-4 rounded-xl red darken-2",staticStyle:{"position":"absolute","z-index":"3"},style:(_vm.$isMobile() ? 'margin-top: -15px; width:150px;' : 'margin-top: -30px; width:200px; font-size: 25px')},[_vm._v("Unlock in shop")]):_vm._e(),(_vm.$isMobile())?_c('v-img',{attrs:{"alt":"Talking","contain":"","src":require("../assets/images/water.png"),"transition":"scale-transition","height":"100"}}):_c('v-img',{attrs:{"alt":"Talking","contain":"","src":require("../assets/images/water.png"),"transition":"scale-transition","height":"150"}}),_c('b',{staticClass:"red--text"},[_vm._v("Characters")])],1)]}}],null,false,2776831195)})],1)],1):_vm._e(),(_vm.level == 1 && _vm.hsk == 1)?_c('v-row',{staticClass:"mb-lg-15 mb-sm-15 mb-0 mt-1 pb-lg-15",staticStyle:{"height":"180px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"mb-0 pa-1 pa-lg-5",attrs:{"cols":"6","lg":"4","align":"center","justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-lg-4 pa-1 rounded-xl",attrs:{"outlined":"","elevation":hover ? 1 : 0,"to":"/memory"}},[(_vm.$isMobile())?_c('v-img',{attrs:{"alt":"Memory","contain":"","src":require("../assets/images/memory1d.png"),"transition":"scale-transition","height":"100"}}):_c('v-img',{attrs:{"alt":"Memory","contain":"","src":require("../assets/images/memory1c.png"),"transition":"scale-transition","height":"150"}}),_c('b',{staticClass:"red--text"},[_vm._v("Memory Image")])],1)]}}],null,false,3171955376)})],1),_c('v-col',{staticClass:"mb-0 pa-1 pa-lg-5",attrs:{"cols":"6","lg":"4","align":"center","justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-lg-4 pa-1 rounded-xl",attrs:{"outlined":"","elevation":hover ? 1 : 0,"to":"/memory2"}},[(_vm.$isMobile())?_c('v-img',{attrs:{"alt":"Memory2","contain":"","src":require("../assets/images/memory2d.png"),"transition":"scale-transition","height":"100"}}):_c('v-img',{attrs:{"alt":"Memory2","contain":"","src":require("../assets/images/memory2c.png"),"transition":"scale-transition","height":"150"}}),_c('b',{staticClass:"red--text"},[_vm._v("Memory Reading")])],1)]}}],null,false,3526245431)})],1)],1):_vm._e(),(_vm.level == 1 && _vm.hsk == 1)?_c('v-row',{staticClass:"mt-0 mt-sm-15 mt-lg-15 pt-lg-15 pt-0",staticStyle:{"height":"180px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"mt-0 pa-1 pa-lg-5",attrs:{"cols":"6","lg":"4","align":"center","justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-lg-4 pa-1 rounded-xl",attrs:{"outlined":"","elevation":hover ? 1 : 0,"to":"/memory3"}},[(_vm.$isMobile())?_c('v-img',{attrs:{"alt":"Talking","contain":"","src":require("../assets/images/memory3d.png"),"transition":"scale-transition","height":"100"}}):_c('v-img',{attrs:{"alt":"Talking","contain":"","src":require("../assets/images/memory3c.png"),"transition":"scale-transition","height":"150"}}),_c('b',{staticClass:"red--text"},[_vm._v("Memory Listen")])],1)]}}],null,false,4233511661)})],1),_c('v-col',{staticClass:"mb-0 pa-1 pa-lg-5",attrs:{"cols":"6","lg":"4","align":"center","justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-lg-4 pa-1 rounded-xl",attrs:{"outlined":"","elevation":hover ? 1 : 0,"to":"/searchgame","disabled":!_vm.openGame.searchgame}},[(!_vm.openGame.searchgame)?_c('h3',{staticClass:"white--text text--lighten-4 rounded-xl red darken-2",staticStyle:{"position":"absolute","z-index":"3"},style:(_vm.$isMobile() ? 'margin-top: -15px; width:150px;' : 'margin-top: -30px; width:200px; font-size: 25px')},[_vm._v("Unlock in shop")]):_vm._e(),(_vm.$isMobile())?_c('v-img',{attrs:{"alt":"SearchGame","contain":"","src":require("../assets/images/search-game.jpeg"),"transition":"scale-transition","height":"100"}}):_c('v-img',{attrs:{"alt":"Memory2","contain":"","src":require("../assets/images/search-game.jpeg"),"transition":"scale-transition","height":"150"}}),_c('b',{staticClass:"red--text"},[_vm._v("Search Game")])],1)]}}],null,false,3589766239)})],1)],1):_vm._e(),_c('v-row')],1)}
var staticRenderFns = []

export { render, staticRenderFns }