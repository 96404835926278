<template>
  <v-container class="py-1 text-center" fill-height fluid>
    <v-row class="pl-5" align="center" justify="center">
        <v-col cols="8">
          <v-row>
            <h2><b>{{this.msg.level}}</b></h2>
          </v-row>
          <v-row>
            <!-- <p v-if="lang === 'NL'" class="px-0 black--text">{{this.msg.description.en}} - {{this.msg.name}}</p>
            <p v-if="lang === 'EN'" class="px-0 black--text">{{this.msg.description.en}} - {{this.msg.description.en}}</p>
            <p v-if="lang === 'Chinese'" class="px-0 black--text">{{this.msg.description.en}} - {{this.msg.description.cn}}</p> -->
            <p class="px-0 black--text">{{this.msg.description.en}} - {{this.msg.description.cn}}</p>
          </v-row>
        </v-col>
        <v-col cols="4" class="text-center">
          
          <!-- <v-list-item-subtitle> -->
              <!-- <h3 class="px-0 black--text"><b>{{this.msg.level}}</b></h3> -->
          <!-- </v-list-item-subtitle> -->

        <!-- <v-btn dark color="green" :to="{ name: 'TalkPage', params: { id: this.msg.level_id }}"> -->
        <v-btn dark color="green" :to="{ path: '/talking/'+ this.msg.level_id}">
          <!-- <v-btn dark color="green" to="/talking/" :id="this.msg.level_id"> -->
            <div>Go</div><v-icon class="pl-1">mdi-play-circle-outline</v-icon>
          </v-btn>
        </v-col>
    </v-row>
    <!-- <v-spacer></v-spacer> -->  
    <!-- <v-row>
      <v-col> 
        <hr>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import store from '../store'
  export default {
    name: 'SongListItem',

    data: () => ({
    }),
    props: {
      msg: Object,
    },
    computed: {
      lang () {
        return store.state.language
      }
    },
    methods: {
    }
  }
</script>
