var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.hsk == 1)?_c('v-row',{staticClass:"mb-lg-15 mt-1 pb-lg-15",staticStyle:{"height":"180px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"mb-0 pa-1 pa-lg-5",attrs:{"cols":"6","lg":"4","align":"center","justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-lg-4 pa-1 rounded-xl",attrs:{"outlined":"","elevation":hover ? 1 : 0,"to":"/onestory"}},[_c('v-img',{attrs:{"alt":"rice","contain":"","src":"https://static.vecteezy.com/system/resources/thumbnails/000/272/352/small_2x/poke-bowl.jpg","transition":"scale-transition","height":"150"}}),_vm._v(" Rice and tea ")],1)]}}],null,false,571683263)})],1),_c('v-col',{staticClass:"mb-0 pa-1 pa-lg-5",attrs:{"cols":"6","lg":"4","align":"center","justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-lg-4 pa-1 rounded-xl",attrs:{"outlined":"","elevation":hover ? 1 : 0,"to":"/storytwo"}},[_c('v-img',{attrs:{"alt":"supermarket","contain":"","src":"https://img.freepik.com/free-vector/supermarket-interior-with-food-shelves-vector_81522-485.jpg?size=626&ext=jpg","transition":"scale-transition","height":"150"}}),_vm._v(" At the supermarket ")],1)]}}],null,false,2507385972)})],1)],1):_vm._e(),_c('v-row',{staticClass:"mt-0 mt-lg-15 pt-lg-15 pt-0",staticStyle:{"height":"180px"},attrs:{"align":"center","justify":"center"}},[(_vm.hsk == 1)?_c('v-col',{staticClass:"mb-0 mt-10 mt-lg-0 pa-1 pa-lg-5",attrs:{"cols":"6","lg":"4","align":"center","justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"rounded-xl",attrs:{"outlined":"","elevation":hover ? 1 : 0,"to":"/storythree","disabled":true}},[_c('v-card-title',{staticClass:"striped",staticStyle:{"margin-bottom":"-38px","z-index":"10","position":"relative","height":"55px"}},[(!_vm.$isMobile())?_c('p',[_vm._v("Under construction")]):_c('p',[_vm._v("Construction.")])]),_c('v-img',{attrs:{"alt":"meeting","contain":"","src":"https://img.freepik.com/free-vector/young-women-meeting_89224-2610.jpg?size=626&ext=jpg","transition":"scale-transition","height":"150"}}),_vm._v(" Meeting people ")],1)]}}],null,false,4294253596)})],1):_vm._e(),(_vm.hsk == 2)?_c('v-col',{staticClass:"mb-0 pa-1 pa-lg-5",attrs:{"cols":"6","lg":"4","align":"center","justify":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-lg-4 pa-1 rounded-xl",attrs:{"outlined":"","elevation":hover ? 1 : 0,"to":"/storyfour"}},[_c('v-img',{attrs:{"alt":"meeting","contain":"","src":"https://i.pinimg.com/originals/49/6b/6e/496b6ea27aea7693c82a1afe8ae872d1.gif","transition":"scale-transition","height":"150"}}),_vm._v(" Signs ")],1)]}}],null,false,2172176464)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }