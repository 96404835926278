<template>
  <v-container>
    <!-- <router-link class="green--text" to="/stories"><h2 v-on:click="stopSound()">Back</h2></router-link> -->
      <pop-up :msg="this.message" :img="this.image" :sound="true"/>
      <pop-up-return :msg="this.message" :points="this.points"/>
      <!-- <p class="mt-15">{{$route.path}}</p> -->
    <v-row class="mt-15 pt-15">
        <v-col cols="12" lg="6" align="center">
            <v-row>
                <v-col cols="12" v-if="!this.againButton">
                    <v-chip
                    class="ma-2 mt-lg-15"
                    color="success"
                    outlined
                    label
                    x-large
                    v-on:click="changeLanguage()"
                    >
                    <!-- <v-icon left>mdi-server-plus</v-icon> -->
                    <v-row v-if="this.flip">
                        <!-- <v-col cols="12" class="pb-0 my-0 pt-5">
                        <h2>{{ this.levels.items[this.number].conversation[this.counter].cnc}}</h2>
                        </v-col> -->
                        <v-col cols="12">
                        <h3>{{ this.levels.items[this.number].conversation[this.counter].cn}} <v-icon class="pl-0">mdi-comment-question-outline</v-icon></h3>
                            <!-- <v-btn dark fab x-small color="green"> -->
                              <!-- <v-icon class="pl-0" small>mdi-comment-question-outline</v-icon> -->
                            <!-- </v-btn> -->
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-col cols="12" class="pb-0 my-0 pt-5">
                            <p>{{ this.levels.items[this.number].conversation[this.counter].en}}</p>                
                        </v-col>
                    </v-row>
                </v-chip>
                </v-col>
                <v-col cols="12" v-else>
                    <v-row>
                        <v-col>
                            <h1 class="ma-0 mt-15 green--text">The end.</h1>
                            <h3>Do you want to read again?</h3>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="mt-15">
                            <v-btn dark color="green" v-on:click="click()">
                            <h3>Start again</h3>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col v-if="!this.againButton" cols="12" lg="6" align="center">
                    <img alt="Talking people" 
                        v-if="$isMobile()"
                        class="my-3"
                        contain
                        height="150"
                        :src="this.levels.items[this.number].conversation[this.counter].img">
                </v-col>
                <v-col v-if="!this.againButton" cols="12">
                    <v-row >
                        <v-col cols="4" v-for="(answer, i) in this.levels.items[this.number].conversation[this.counter].answers" :key="i">
                            <sound-button-small :item="answer"/>
                            <!-- <v-btn width="300" dark color="green" v-on:click="click()">
                            <h3>{{ this.levels.items[this.number].conversation[this.counter].correct_answer}}</h3>
                            </v-btn> -->
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn dark color="green" v-on:click="click()">
                            <h3>Next</h3>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <!-- <v-btn width="300" dark color="green" v-on:click="click()">
                            <h3>{{ this.levels.items[this.number].conversation[this.counter].wrong_answer1}}</h3>
                            </v-btn> -->
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <!-- <v-btn width="300" dark color="green" v-on:click="click()">
                            <h3>{{ this.levels.items[this.number].conversation[this.counter].wrong_answer2}}</h3>
                            </v-btn> -->
                        </v-col>
                    </v-row>
                </v-col>
                </v-row>
            </v-col>
            <v-col v-if="!this.againButton" cols="12" lg="6" align="center">
                <img alt="Talking people" 
                    v-if="!$isMobile()"
                    class="my-3"
                    contain
                    height="350"
                    :src="this.levels.items[this.number].conversation[this.counter].img">
            </v-col>
                <!-- <audio ref="audioElm" :src="require('@/' + this.levels.items[this.number].conversation[this.counter].voice)"></audio> -->
        </v-row>
    </v-container>
</template>

<script>
import Levels from '@/assets/json/storythree.json'
import SoundButtonSmall from '@/components/SoundButtonSmall.vue'
import PopUp from '../components/PopUp';
import PopUpReturn from '../components/PopUpReturn';
import store from '../store'

export default {
  name: 'TalkPage',
  components: {
    SoundButtonSmall,
    PopUp,
    PopUpReturn,
  },
  data () {
    return {
        levels: Levels,
        number: 0,
        counter: 0,
        flip: true,
        playlist: [],
        againButton: false,
        message: "Listen to the sentence and fill in the phone number.",
        image: "assets/images/storythree.png",
        route: this.$route.path
    }
  },
  mounted() {
    this.levels.items[this.number].conversation.forEach(element => {
      let music = new Audio(require('@/' + element.voice));
      this.playlist.push(music);
    })
    // this.playSound();
  },
  // watch:{
  //   path (to, from){
  //     console.log("hello")
  //     console.log(to)
  //     console.log(from)
  //     // $route.params.id
  //     this.stopSound();
  //   }
  // },
  computed:{
    path(){
      console.log(store.state.stopPlaying)
      return store.state.stopPlaying
    }
  },
  methods: {
      click() {
        if (this.counter >= (this.levels.items[this.number].conversation.length) ){
          // this.$refs.audioElm.pause();
          // this.$refs.audioElm.currentTime = 0;
          this.counter = 0;
          this.playSound();
          this.flip = true;
          this.againButton = false;
          // console.log("if");
          // console.log(this.counter);
          // console.log(this.levels.items[this.number].conversation.length)
        } else if (this.counter >= (this.levels.items[this.number].conversation.length - 1)) {
          this.againButton = true;
          this.counter += 1;
        } else {
          // this.$refs.audioElm.pause();
          // this.$refs.audioElm.currentTime = 0;
          this.stopSound()
          this.counter += 1;
          this.playSound()
          this.flip = true;
          // console.log("else");
          // console.log(this.counter);
          // console.log(this.levels.items[this.number].conversation.length)
        }
      },
      playSound () {
        this.playlist[this.counter].play();
      },
      stopSound(){
        console.log("stop")
        this.playlist[this.counter].pause();
        this.playlist[this.counter].currentTime = 0;
      },
      changeLanguage(){
        if (this.flip === true){
          this.flip = false;
        } else {
          this.flip = true;
        }
      }
    }
}
</script>