<template>
  <v-container>
    <!-- <router-link class="green--text" to="/games"><h2>Back</h2></router-link> -->
    <v-row>
      <v-col cols="12" class="col-lg-5">
        <!-- <h1 v-if="!$isMobile()" class="red--text">Listening</h1> -->
        <pop-up :msg="this.message" :img="this.image"/>
        <pop-up-return :msg="this.message" :points="this.points" back="games"/>
      </v-col>
      <!-- <v-col cols="12" class="col-lg-5"><h1 class="green--text">Points: {{this.points}}</h1></v-col> -->
      <!-- <v-col v-if="!$isMobile()" cols="2" class="mt-5">
        <h3 v-if="this.setFound == true" class="green--text">YES! A SET!</h3>
        <h3 v-if="this.setFound == false" class="red--text">Not a set, too bad..</h3>
        <h3 v-if="this.setFound == null">Click a card</h3>
      </v-col> -->
    </v-row>
    <!-- <v-row v-if="this.cardSet.filter(element => element.guessed == false).length == 0" class="text-center">
      <v-col>
        <v-btn dark color="green" v-on:click="startAgain()">
          <h3>Play again</h3>
        </v-btn>
      </v-col>
    </v-row> -->
    <v-row class="text-center mt-1 px-1" justify="center">
      <v-col
        v-for="(card, i) in cardSet"
        :key="i"
        :href="card.href"
        class="pa-0"
        cols="4" lg="3"
        @click="turnCard(card)"
      >
        <MemoryCard :msg="card"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MemoryCard from '../components/MemoryCard';
import PopUp from '../components/PopUp';
import PopUpReturn from '../components/PopUpReturn';
import store from '../store'

export default {
  name: 'MemoryCanvas',
  components: {
    MemoryCard,
    PopUp,
    PopUpReturn,
  },
  created: function () {
    let array = this.cards.filter(element => element.level == store.state.level && element.hsk == store.state.hsk)

    let currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    this.cardSet = array;
  },
  methods: {
    // reloadPage(){
    //   window.location.reload()
    // },
    startAgain(){
      let array = this.cards.filter(element => element.level == store.state.level && element.hsk == store.state.hsk)
      // console.log(array)

      for (let i = 0; i < array.length; i++) {
        array[i].show = false
        array[i].select = false
        array[i].guessed = false
        // console.log(array[i])
      }
      this.cardsTurned = 0
      this.setFound = null
      this.cardSet = null
      // this.points = 0

      let currentIndex = array.length, temporaryValue, randomIndex;
      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }
      this.cardSet = array;
    },
    turnCard(card){
      if (card.select == true){
        if (this.cardsTurned == 0){
          this.cardsTurned = 1
        } else {
          this.cardsTurned = 2
          const result = this.cardSet.filter(element => element.select == true);
            if (result[0].set == result[1].set){
                this.setFound = true
                // this.points = this.points + 10;

                let cardOne = this.cardSet.filter(element => element.select == true)[0]
                cardOne.select = false

                let cardTwo = this.cardSet.filter(element => element.select == true)[0]
                cardTwo.select = false
                
                this.cardsTurned = 0
                this.setFound = null

                setTimeout( () => {
                  cardOne.guessed = true
                  cardTwo.guessed = true

                  cardOne.show = false
                  cardTwo.show = false

                  if (this.cardSet.filter(element => element.guessed == false).length == 0){
                    this.gameOver()
                  }
                }, 1000);

              } else {
                this.setFound = false
                // this.points = this.points - 5;
                let cardOne = this.cardSet.filter(element => element.select == true)[0]
                cardOne.select = false
                let cardTwo = this.cardSet.filter(element => element.select == true)[0]
                cardTwo.select = false

                this.cardsTurned = 0
                this.setFound = null

                setTimeout( () => {
                  cardOne.show = false
                  cardTwo.show = false
                }, 1000);
              }
        }
      } else {
          if (this.cardsTurned == 2){
            this.cardsTurned = 1
            this.setFound = null
          } else {
            this.cardsTurned = 0
          }
      }
    },
    gameOver() {
      store.commit('GAMEOVER', true);
    }
  },
  // computed: {
  //   shuffledCards() {
  //     let array = this.cards
  //     let currentIndex = array.length, temporaryValue, randomIndex;
  //     while (0 !== currentIndex) {
  //       randomIndex = Math.floor(Math.random() * currentIndex);
  //       currentIndex -= 1;
  //       temporaryValue = array[currentIndex];
  //       array[currentIndex] = array[randomIndex];
  //       array[randomIndex] = temporaryValue;
  //     }
  //     return array;
  //   }
  // },
  data: () => ({
    cardsTurned: 0,
    setFound: null,
    cardSet: null,
    points: 20,
    message: "Match the sound to the right picture.",
    image: "assets/images/memory3-expl.png",
    cards: [
            {
        level: 0,
        hsk: 1,
        id: '1',
        set: '1',
        img: 'https://media.istockphoto.com/vectors/sound-icon-flat-volume-sign-symbol-music-player-sticker-vector-id1193300702?b=1&k=6&m=1193300702&s=612x612&w=0&h=SjNhPfb9j0CqukVZTNxr1GGNyXY754--bYfFNx8Abio=',
        back: '../assets/logo.svg',
        sound: "assets/sentences/1-yi.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: '2',
        set: '1',
        img: 'https://www.pinclipart.com/picdir/middle/16-161946_animal-clip-art-number-one-vector-site-number.png',
        back: '../assets/logo.svg',
        sound: "assets/sentences/1-yi.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: '3',
        set: '2',
        img: 'https://media.istockphoto.com/vectors/sound-icon-flat-volume-sign-symbol-music-player-sticker-vector-id1193300702?b=1&k=6&m=1193300702&s=612x612&w=0&h=SjNhPfb9j0CqukVZTNxr1GGNyXY754--bYfFNx8Abio=',
        back: '../assets/logo.svg',
        sound: "assets/sentences/2-er.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: '4',
        set: '2',
        img: 'https://i.pinimg.com/originals/7c/23/2b/7c232bbc2fb9ec5f35948233bc037f50.png',
        back: '../assets/logo.svg',
        sound: "assets/sentences/2-er.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: '5',
        set: '3',
        img: 'https://media.istockphoto.com/vectors/sound-icon-flat-volume-sign-symbol-music-player-sticker-vector-id1193300702?b=1&k=6&m=1193300702&s=612x612&w=0&h=SjNhPfb9j0CqukVZTNxr1GGNyXY754--bYfFNx8Abio=',
        back: '../assets/logo.svg',
        sound: "assets/sentences/3-san.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: '6',
        set: '3',
        img: 'https://media.istockphoto.com/photos/neon-3d-font-blue-and-pink-neon-light-3d-rendering-number-3-picture-id1250020951?k=6&m=1250020951&s=170667a&w=0&h=I_UW6D-gOxDv_wJnpg0rwRpcOpaX7UPG1ml5DLP-Qmk=',
        back: '../assets/logo.svg',
        sound: "assets/sentences/3-san.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: '7',
        set: '4',
        img: 'https://media.istockphoto.com/vectors/sound-icon-flat-volume-sign-symbol-music-player-sticker-vector-id1193300702?b=1&k=6&m=1193300702&s=612x612&w=0&h=SjNhPfb9j0CqukVZTNxr1GGNyXY754--bYfFNx8Abio=',
        back: '../assets/logo.svg',
        sound: "assets/sentences/4-si.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: '8',
        set: '4',
        img: 'https://cdn.iconscout.com/icon/free/png-512/4-four-digital-number-numerical-numbers-36017.png',
        back: '../assets/logo.svg',
        sound: "assets/sentences/4-si.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: '9',
        set: '5',
        img: 'https://media.istockphoto.com/vectors/sound-icon-flat-volume-sign-symbol-music-player-sticker-vector-id1193300702?b=1&k=6&m=1193300702&s=612x612&w=0&h=SjNhPfb9j0CqukVZTNxr1GGNyXY754--bYfFNx8Abio=',
        back: '../assets/logo.svg',
        sound: "assets/sentences/5-wu.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: '10',
        set: '5',
        img: 'https://images-na.ssl-images-amazon.com/images/I/71OubAsefxL._AC_SL1500_.jpg',
        back: '../assets/logo.svg',
        sound: "assets/sentences/5-wu.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: '11',
        set: '6',
        img: 'https://media.istockphoto.com/vectors/sound-icon-flat-volume-sign-symbol-music-player-sticker-vector-id1193300702?b=1&k=6&m=1193300702&s=612x612&w=0&h=SjNhPfb9j0CqukVZTNxr1GGNyXY754--bYfFNx8Abio=',
        back: '../assets/logo.svg',
        sound: "assets/sentences/6-liu.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 1,
        id: '12',
        set: '6',
        img: 'https://cdn.pixabay.com/photo/2013/07/12/16/22/number-150795_640.png',
        back: '../assets/logo.svg',
        sound: "assets/sentences/6-liu.mp3",
        show: false,
        select: false,
        guessed: false,
      },

      // {
      //   level: 2,
      //   id: '1',
      //   set: '1',
      //   img: 'https://media.istockphoto.com/vectors/sound-icon-flat-volume-sign-symbol-music-player-sticker-vector-id1193300702?b=1&k=6&m=1193300702&s=612x612&w=0&h=SjNhPfb9j0CqukVZTNxr1GGNyXY754--bYfFNx8Abio=',
      //   back: '../assets/logo.svg',
      //   sound: "assets/sentences2/yu-fish.mp3",
      //   show: false,
      //   select: false,
      //   guessed: false,
      // },
      // {
      //   level: 2,
      //   id: '2',
      //   set: '1',
      //   img: 'https://media.istockphoto.com/photos/red-apple-with-leaf-isolated-on-white-background-picture-id185262648?k=6&m=185262648&s=170667a&w=0&h=iJfV0M0WkvcsFLBOMJQUquqL_1xHNZSbYLiXjpiwAjA=',
      //   back: '../assets/logo.svg',
      //   sound: "assets/sentences2/yu-fish.mp3",
      //   show: false,
      //   select: false,
      //   guessed: false,
      // },
      {
        level: 1,
        hsk: 1,
        id: '1',
        set: '1',
        img: 'https://media.istockphoto.com/vectors/sound-icon-flat-volume-sign-symbol-music-player-sticker-vector-id1193300702?b=1&k=6&m=1193300702&s=612x612&w=0&h=SjNhPfb9j0CqukVZTNxr1GGNyXY754--bYfFNx8Abio=',
        back: '../assets/logo.svg',
        sound: "assets/sentences3/shangdian.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 1,
        hsk: 1,
        id: '2',
        set: '1',
        img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Store_Building_Flat_Icon_Vector.svg/1024px-Store_Building_Flat_Icon_Vector.svg.png',
        back: '../assets/logo.svg',
        sound: "assets/sentences3/shangdian.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 1,
        hsk: 1,
        id: '3',
        set: '2',
        img: 'https://media.istockphoto.com/vectors/sound-icon-flat-volume-sign-symbol-music-player-sticker-vector-id1193300702?b=1&k=6&m=1193300702&s=612x612&w=0&h=SjNhPfb9j0CqukVZTNxr1GGNyXY754--bYfFNx8Abio=',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/cha-tea.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 1,
        hsk: 1,
        id: '4',
        set: '2',
        img: 'https://media.istockphoto.com/vectors/green-tea-in-cup-and-green-leaves-vector-illustration-in-flat-design-vector-id1157013982?b=1&k=6&m=1157013982&s=612x612&w=0&h=Acn8wqQt17FDf3uUkQJnpuC6gpp3-tYDAkqI5bHAJzA=',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/cha-tea.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 1,
        hsk: 1,
        id: '5',
        set: '3',
        img: 'https://media.istockphoto.com/vectors/sound-icon-flat-volume-sign-symbol-music-player-sticker-vector-id1193300702?b=1&k=6&m=1193300702&s=612x612&w=0&h=SjNhPfb9j0CqukVZTNxr1GGNyXY754--bYfFNx8Abio=',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/fan.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 1,
        hsk: 1,
        id: '6',
        set: '3',
        img: 'https://media.istockphoto.com/vectors/rice-bowl-vector-id547231286?b=1&k=6&m=547231286&s=612x612&w=0&h=oG90dozDg7BKAA-XS74gMyD2PuesQnr6tEwR-WRSicw=',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/fan.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 1,
        hsk: 1,
        id: '7',
        set: '4',
        img: 'https://media.istockphoto.com/vectors/sound-icon-flat-volume-sign-symbol-music-player-sticker-vector-id1193300702?b=1&k=6&m=1193300702&s=612x612&w=0&h=SjNhPfb9j0CqukVZTNxr1GGNyXY754--bYfFNx8Abio=',
        sound: "assets/sentences2/shui-water.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 1,
        hsk: 1,
        id: '8',
        set: '4',
        img: 'https://img.freepik.com/free-vector/glass-water-icon_68196-604.jpg?size=338&ext=jpg',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/shui-water.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 1,
        hsk: 1,
        id: '9',
        set: '5',
        img: 'https://media.istockphoto.com/vectors/sound-icon-flat-volume-sign-symbol-music-player-sticker-vector-id1193300702?b=1&k=6&m=1193300702&s=612x612&w=0&h=SjNhPfb9j0CqukVZTNxr1GGNyXY754--bYfFNx8Abio=',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/bu-not.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 1,
        hsk: 1,
        id: '10',
        set: '5',
        img: 'https://img.freepik.com/free-vector/stop-sign-icon-notifications-that-anything_68708-468.jpg?size=338&ext=jpg&ga=GA1.2.1356500412.1617148800',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/bu-not.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 1,
        hsk: 1,
        id: '11',
        set: '6',
        img: 'https://media.istockphoto.com/vectors/sound-icon-flat-volume-sign-symbol-music-player-sticker-vector-id1193300702?b=1&k=6&m=1193300702&s=612x612&w=0&h=SjNhPfb9j0CqukVZTNxr1GGNyXY754--bYfFNx8Abio=',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/ri-hot.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 1,
        hsk: 1,
        id: '12',
        set: '6',
        img: 'https://whitehorsenetball.org.au/wp-content/uploads/2019/03/weather-2.jpg',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/ri-hot.mp3",
        show: false,
        select: false,
        guessed: false,
      },

      {
        level: 0,
        hsk: 2,
        id: '1',
        set: '1',
        img: 'https://media.istockphoto.com/vectors/sound-icon-flat-volume-sign-symbol-music-player-sticker-vector-id1193300702?b=1&k=6&m=1193300702&s=612x612&w=0&h=SjNhPfb9j0CqukVZTNxr1GGNyXY754--bYfFNx8Abio=',
        back: '../assets/logo.svg',
        sound: "assets/sentences3/chukou.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 2,
        id: '2',
        set: '1',
        img: 'https://www.omni-hk.com/image/cache/catalog/Products/Emergency%20Lighting%20Series/Automatic%20Emergency%20Light/LEDX-301-500x500.png',
        back: '../assets/logo.svg',
        sound: "assets/sentences3/chukou.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 2,
        id: '3',
        set: '2',
        img: 'https://media.istockphoto.com/vectors/sound-icon-flat-volume-sign-symbol-music-player-sticker-vector-id1193300702?b=1&k=6&m=1193300702&s=612x612&w=0&h=SjNhPfb9j0CqukVZTNxr1GGNyXY754--bYfFNx8Abio=',
        back: '../assets/logo.svg',
        sound: "assets/sentences3/rukou.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 2,
        id: '4',
        set: '2',
        img: 'https://www.freevector.com/uploads/vector/preview/27709/Entrance_vector_1.jpg',
        back: '../assets/logo.svg',
        sound: "assets/sentences3/rukou.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 2,
        id: '5',
        set: '3',
        img: 'https://media.istockphoto.com/vectors/sound-icon-flat-volume-sign-symbol-music-player-sticker-vector-id1193300702?b=1&k=6&m=1193300702&s=612x612&w=0&h=SjNhPfb9j0CqukVZTNxr1GGNyXY754--bYfFNx8Abio=',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/yu-fish.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 2,
        id: '6',
        set: '3',
        img: 'https://media.istockphoto.com/vectors/cute-fish-character-cartoon-vector-illustration-vector-id1045561036?b=1&k=6&m=1045561036&s=612x612&w=0&h=g3o38iDSBk1n-_ak0orDkS2FoPo9U7HvyObIGtQQeWg=',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/yu-fish.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 2,
        id: '7',
        set: '4',
        img: 'https://media.istockphoto.com/vectors/sound-icon-flat-volume-sign-symbol-music-player-sticker-vector-id1193300702?b=1&k=6&m=1193300702&s=612x612&w=0&h=SjNhPfb9j0CqukVZTNxr1GGNyXY754--bYfFNx8Abio=',
        back: '../assets/logo.svg',
        sound: "assets/sentences3/xiaoxin.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 2,
        id: '8',
        set: '4',
        img: 'https://media.istockphoto.com/photos/be-careful-sign-watch-your-steps-picture-id612524656',
        back: '../assets/logo.svg',
        sound: "assets/sentences3/xiaoxin.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 2,
        id: '9',
        set: '5',
        img: 'https://media.istockphoto.com/vectors/sound-icon-flat-volume-sign-symbol-music-player-sticker-vector-id1193300702?b=1&k=6&m=1193300702&s=612x612&w=0&h=SjNhPfb9j0CqukVZTNxr1GGNyXY754--bYfFNx8Abio=',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/bing-cold.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 2,
        id: '10',
        set: '5',
        img: 'https://www.freevector.com/uploads/vector/preview/30450/Sketchy-Ice-Cubes.jpg',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/bing-cold.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 2,
        id: '11',
        set: '6',
        img: 'https://media.istockphoto.com/vectors/sound-icon-flat-volume-sign-symbol-music-player-sticker-vector-id1193300702?b=1&k=6&m=1193300702&s=612x612&w=0&h=SjNhPfb9j0CqukVZTNxr1GGNyXY754--bYfFNx8Abio=',
        sound: "assets/sentences2/mian.mp3",
        show: false,
        select: false,
        guessed: false,
      },
      {
        level: 0,
        hsk: 2,
        id: '12',
        set: '6',
        img: 'https://image.freepik.com/vrije-vector/aziatische-traditionele-gerechten-met-noedels-en-eetstokjes_29190-4960.jpg',
        back: '../assets/logo.svg',
        sound: "assets/sentences2/mian.mp3",
        show: false,
        select: false,
        guessed: false,
      },
    ],
  }),
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* h3 {
  margin: 40px 0 0;
} */
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
