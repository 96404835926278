<template>
  <v-container class="background-talking">
    <!-- <router-link class="green--text" to="/"><h2>Back</h2></router-link> -->
    <!-- <h1>Talking</h1> -->
    <v-row
      v-for="(song, i) in songs.items"
      :key="i"
      class="pt-5"
    >
      <v-col cols="12 pa-0 px-5 ma-0">
        <level-listitem :msg="song"/>
      </v-col>
    </v-row>
 </v-container>
</template>

<script>
import LevelListitem from '@/components/LevelListitem.vue'
import Levels from '@/assets/json/levels.json'
import Songs from '@/assets/json/songs.json'

export default {
  name: 'LevelPage',
  components: {
    LevelListitem
  },
  data () {
    return {
        levels: Levels,
        songs: Songs,
    }
  },
}
</script>

<style lang="scss" scoped>
.background-talking {
  background-image: 
  radial-gradient(circle, rgba(255, 255, 255, 0.596), rgb(255, 255, 255)), 

  url("https://phassociation.org/wp-content/uploads/2018/01/light-speech-bg.jpg");
  height: 100%;
  width: 100vw;
  background-repeat: repeat;
}
</style>