<template>
  <v-container class="pt-lg-15 pt-0">
    <!-- <router-link class="red--text" to="/stories"><h2 v-on:click="stopSound()">Back</h2></router-link> -->
    <v-col v-if="!this.againButton" cols="1" class="text-center mt-15 pt-10 mt-lg-0 pt-lg-0" style="position:absolute;"><h2 class="red--text rounded-xl red lighten-4" style="position:absolute; width:70px">&#165; {{this.points}}</h2></v-col>
    <pop-up :msg="this.message" :img="this.image"/>
    <pop-up-return :msg="this.message" :points="this.points" back="games"/>
      <v-row>
        <v-col cols="12" lg="6" align="center">
            <v-row>
                <v-col cols="12" v-if="!this.againButton">
                    <v-chip
                    class="ma-lg-2 ma-0 mt-lg-15 mt-2"
                    color="red"
                    outlined
                    label
                    x-large
                    v-on:click="changeLanguage()"
                    >
                    <v-row>
                        <v-col cols="12">
                        <h3 >{{ this.levels.items[this.number].conversation[this.counter].cn}}</h3>
                        </v-col>
                    </v-row>
                </v-chip>
                </v-col>
                <v-col cols="12" v-else>
                    <v-row class="text-center mt-15 pt-15">
                        <v-col cols="12">
                          <h1 class="red--text">Well done! You got &#165;{{this.points}} out of &#165;15!</h1>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="mt-15">
                            <v-btn dark color="red" v-on:click="click()">
                            <h3>Start again</h3>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col v-if="!this.againButton" cols="12" lg="6" align="center">
                  <img alt="Talking people" 
                      v-if="$isMobile()"
                      class="my-0"
                      contain
                      height="150"
                      :src="this.levels.items[this.number].conversation[this.counter].img">
                </v-col>
                <v-col v-if="!this.againButton" cols="12">
                    <v-row>
                        <v-col v-on:click="buttonChoose(answer)" cols="4" v-for="(answer, i) in this.answers" :key="i">
                            <answer-card :item="answer"/>
                        </v-col>
                    </v-row>
                    <v-row class="text-center">
                        <v-col v-if="!disableButton">
                            <v-btn :disabled="button == null" class="white--text" color="light-green" v-on:click="check()">
                            <h3>Check answer</h3>
                            </v-btn>
                        </v-col>
                        <v-col v-else>
                            <v-btn :disabled="!disableButton" dark color="light-green" class="white--text" v-on:click="click()">
                            <h3>Next</h3>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                </v-row>
            </v-col>
            <v-col v-if="!this.againButton" cols="12" lg="6" align="center">
                <img alt="Talking people" 
                    v-if="!$isMobile()"
                    class="my-3 rounded-xl"
                    contain
                    height="350"
                    :src="this.levels.items[this.number].conversation[this.counter].img">
            </v-col>
                <!-- <audio ref="audioElm" :src="require('@/' + this.levels.items[this.number].conversation[this.counter].voice)"></audio> -->
        </v-row>
    </v-container>
</template>

<script>
import Levels from '@/assets/json/write.json'
// import SoundButton from '@/components/SoundButton.vue'
import AnswerCard from '@/components/AnswerCard.vue'
import PopUp from '../components/PopUp';
import PopUpReturn from '../components/PopUpReturn';
import store from '../store'

export default {
  name: 'TalkPage',
  components: {
    // SoundButton,
    PopUp,
    AnswerCard,
    PopUpReturn,
  },
  data () {
    return {
        levels: Levels,
        number: 0,
        counter: 0,
        flip: true,
        playlist: [],
        againButton: false,
        answerChoice: true,
        // disableButton: false,
        points: 0,
        feedback: "What's the answer?",
        message: "Which character is the right answer?",
        image: "assets/images/water.png",
    }
  },
  mounted() {
    this.levels.items[this.number].conversation.forEach(element => {
      let music = new Audio(require('@/' + element.voice));
      this.playlist.push(music);
    })
    this.buttonCheck(false)
    this.buttonChoose(null)
    // this.playSound();
  },
  computed:{
    answers(){
      let answers = this.levels.items[this.number].conversation[this.counter].answers
      return answers
    },
    button () {
      return store.state.button
    },
    disableButton(){
      let checked = store.state.checked
      // console.log(checked)
      return checked
    }
  },
  methods: {
      click() {
        // this.feedback = "What's the answer?"
        // this.disableButton = false
        this.buttonCheck(false)
        this.buttonChoose(null)
        if (this.counter >= (this.levels.items[this.number].conversation.length) ){
          // this.$refs.audioElm.pause();
          // this.$refs.audioElm.currentTime = 0;
          this.counter = 0;
          this.points = 0
          // this.playSound();
          this.flip = true;
          // this.againButton = false;
        } else if (this.counter >= (this.levels.items[this.number].conversation.length - 1)) {
          // this.againButton = true;
          this.counter += 1;
          this.gameOver()
        } else {
          // this.$refs.audioElm.pause();
          // this.$refs.audioElm.currentTime = 0;
          this.stopSound()
          this.counter += 1;
          // this.playSound()
          this.flip = true;
        }
      },
      check(){
        this.buttonCheck(true)
        // this.disableButton = true
        if (store.state.button.correct === true){
          this.points += 10
          this.feedback = "Yayy!"
        } else {
          this.feedback = "Nope :("
          console.log("wrongg")
        }
      },
      startAgain(){
        this.counter += 1;
        this.click()
      },
      playSound () {
        this.playlist[this.counter].play();
      },
      stopSound(){
        this.playlist[this.counter].pause();
        this.playlist[this.counter].currentTime = 0;
        // this.buttonChoose(null)
      },
      changeLanguage(){
        if (this.flip === true){
          this.flip = false;
        } else {
          this.flip = true;
        }
      },
      buttonChoose(answer) {
        store.commit('BUTTONSTATE', answer);
      },
      buttonCheck(check) {
        store.commit('BUTTONCHECK', check);
      },
      gameOver() {
        store.commit('GAMEOVER', true);
      }
    }
}
</script>
