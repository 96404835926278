<template>
  <v-container class="my-1 py-0 px-15" style="cursor: pointer;">
    <v-row @click="playSound()">
      <v-col v-if="this.songPaused" cols="10" class="mt-2">
        <p class="ma-0">{{this.msg.description.cn}}</p>
        <p class="ma-0">{{this.msg.description.pinyin}}</p>
      </v-col>
      <v-col v-else cols="10" class="mt-2">
        <p class="ma-0 red--text">{{this.msg.description.cn}}</p> 
        <p class="ma-0 red--text">{{this.msg.description.pinyin}}</p> 
      </v-col>
      <v-col cols="2" class="text-right">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
            <!-- <v-btn dark fab x-small color="green"> -->
              <v-icon class="pl-0" color="red">mdi-comment-question-outline</v-icon>
            <!-- </v-btn> -->
            </span>
          </template>
          <span>{{this.msg.description.en}}</span>
        </v-tooltip>
        <!-- <v-icon class="pl-1">mdi-music</v-icon> -->
        <!-- {{this.msg.sound}} -->
        <!-- <v-btn dark color="green" >
            <div v-if="this.songPaused">Play</div><div v-else>Stop</div> <v-icon class="pl-1">mdi-music</v-icon>
        </v-btn> -->
        <!-- <audio ref="audioElm" src="@/assets/songs/level_1_chinese_song.mp3"></audio> -->
        <audio ref="audioElm" :src="require('@/' + this.msg.sound)"></audio> 
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import store from '../store'
  export default {
    name: 'WordItem',
    data: () => ({
      songPaused: true,
    }),
    props: {
      msg: Object,
    },
    methods: {
      playSound () {
        if (this.$refs.audioElm.paused){
          this.$refs.audioElm.play();
          this.songPaused = this.$refs.audioElm.paused;
        } else {
          this.$refs.audioElm.pause();
          this.$refs.audioElm.currentTime = 0;
          this.songPaused = this.$refs.audioElm.paused;
        }
      }
    }
  }
</script>
