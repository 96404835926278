<template>
  <v-row justify="center">
    <v-dialog
      v-model="gameOver"
      persistent
      max-width="400"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
        </v-btn>
      </template> -->
      <v-card class="rounded-xl orange lighten-5">
        <v-card-title class="headline white--text red lighten-2 mb-10">
          <h3>Play again?</h3>
        </v-card-title>
        <v-card-text class="text-center mb-1 mb-lg-3">
          <h3 v-if="$isMobile()"><span class="red--text rounded-xl red lighten-4 pa-2" style="width:70px">&#165; {{this.points}}</span> will be added to your wallet!</h3>
          <h2 v-else><span class="red--text rounded-xl red lighten-4 pa-2" style="width:70px">&#165; {{this.points}}</span> will be added to your wallet!</h2>
        </v-card-text>

        <v-card-text v-if="!$isMobile()">
          <v-btn
            color="red darken-1"
            class="rounded-xl mr-10"
            dark
            @click="gameAgain()"

          >
            Play again
          </v-btn>
          <v-btn
            color="red darken-1"
            class="rounded-xl"
            dark
            @click="goBack()"
          >
            Back to {{this.back}}
          </v-btn>
        
        
        </v-card-text>


        <!-- <v-card-text><v-img class="rounded-xl" elevation="12" style="border: 5px solid red;" :src="require('@/' + this.img)"/></v-card-text> -->
        <v-card-actions v-if="$isMobile()" class="pt-0 pb-5">
          <v-spacer></v-spacer>
          <!-- <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Disagree
          </v-btn> -->



          <v-btn
            color="red darken-1"
            class="rounded-xl mr-4"
            dark
            @click="gameAgain()"

          >
            Play again
          </v-btn>
          <v-btn
            color="red darken-1"
            class="rounded-xl"
            dark
            @click="goBack()"
          >
            Back to {{this.back}}
          </v-btn>


          <!-- <v-spacer></v-spacer> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import store from '../store'

export default {
  created: function () {
    store.commit('GAMEOVER', false);
  },
  computed: {
    gameOver () {
      console.log(store.state.gameOver)
      return store.state.gameOver
    },
  },
  // data () {
  //   return {
  //     points: 20,
  //   }
  // },
  props: {
    msg: String,
    img: String,
    points: Number,
    back: String,
  },
  methods: {
    gameAgain(){
      console.log("again")
      this.$parent.startAgain()
      this.popupAway()
    },
    goBack(){
      this.$router.back()
      this.popupAway()
    },
    popupAway() {
      this.addScore(this.points)
      store.commit('GAMEOVER', false);
    },
    addScore(score) {
      store.commit('ADDSCORE', score);
    },
  }
}
</script>