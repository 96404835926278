<template>
  <div class="pa-3 py-5 pa-lg-5  ml-lg-15 ml-md-15 pl-md-10">
    <v-card v-if="clicked && id == showClicked" :disabled="true" tile outlined elevation="1" :style="showCorrect ? 'border-color: #60e072; border-width: 5px;' : 'border-color: #db3b3b; border-width: 5px;'" class="py-1 py-lg-5 px-lg-3 px-1" :height="$isMobile() ? '120' : '160'" width="140" v-on:click="msg.show=false" @click="playSound()">
      <v-row>
        <v-col>
          <v-img
            :src="msg.img"
            contain
            height="110"
          />
        </v-col>

        <!-- {{this.showCorrect}} - 
        {{this.showClicked}} - 
        {{this.msg.id}} - 
        {{this.id}} - 
        {{this.correctCard}} -->

      </v-row>
    </v-card>


    <v-card v-else-if="clicked" :disabled="true" tile outlined elevation="1" :style="correctCard == id ? 'border-color: #60e072; border-width: 5px;' : 'border-color: transparent; border-width: 5px; opacity: 0.9;'" class="py-1 py-lg-5 px-lg-3 px-1" :height="$isMobile() ? '120' : '160'" width="140" v-on:click="msg.show=false" @click="playSound()">
      <v-row >
        <v-col>
          <v-img
            :src="msg.img"
            contain
            height="110"
          />
        </v-col>
        <!-- {{this.showCorrect}} - 
        {{this.showClicked}} - 
        {{this.msg.id}} - 
        {{this.id}} - 
        {{this.correctCard}} -->

      </v-row>
    </v-card>

    <v-card v-else tile outlined elevation="1" style="border-color: transparent; border-width: 5px;" class="py-1 py-lg-5 px-lg-3 px-1" :height="$isMobile() ? '120' : '160'" width="140" v-on:click="msg.show=false" @click="playSound()">
      <v-row >
        <v-col>
          <v-img
            :src="msg.img"
            contain
            height="110"
          />
        </v-col>
        <!-- {{this.showCorrect}} - 
        {{this.showClicked}} - 
        {{this.msg.id}} - 
        {{this.id}} - 
        {{this.correctCard}} -->

      </v-row>
    </v-card>



    <audio ref="audioElm" :src="require('@/' + this.msg.sound)"></audio>
    
  </div>
</template>

<script>
import store from '../store'

export default {
  name: 'HelloWorld',
  props: {
    msg: Object,
    id: Number,
    correctCard: Number,
  },
  computed:{
    showCorrect(){
      return store.state.showCorrect // Was the one clicked correct?
    },
    showClicked(){
      return store.state.showClicked // Which one is clicked?
    },
    clicked(){
      return store.state.clicked // Is clicked?
    }
  },
  methods: {
    playSound () {
      if (this.$refs.audioElm.paused){
        this.$refs.audioElm.play();
        // this.songPaused = this.$refs.audioElm.paused;
      } else {
        this.$refs.audioElm.pause();
        this.$refs.audioElm.currentTime = 0;
        // this.songPaused = this.$refs.audioElm.paused;
      }
    }
  }
}
</script>
