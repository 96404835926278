<template>
   <v-container class="background-music">
    <!-- <router-link class="red--text" to="/" v-on:click="stopSound()"><h2>Back</h2></router-link> -->
    <!-- <h1>Songs</h1> -->
    <!-- <v-row
      v-for="(song, i) in songs.items"
      :key="i"
    >
      <v-col cols="12 pa-0 px-5 ma-0">
        <song-listitem :msg="song"/>
      </v-col>
    </v-row> -->

    <template>
      <v-expansion-panels>
        <v-expansion-panel
          v-for="(song, i) in songs.items"
          :key="i"
          style="background: transparent;"
          class="red lighten-5"
        >
          <v-expansion-panel-header>
            <song-listitem :msg="song"/>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pl-lg-10 pb-5 pl-5">
            <v-row>
              <v-col cols="12" lg="4">
                <h4 v-for="(lyric, i) in song.lyrics"
                  :key="i"
                  >
                  {{lyric}}
                </h4>
              </v-col>
              <v-col cols="12" lg="4">
                <h4 v-for="(lyric, i) in song.lyricscn"
                  :key="i"
                  >
                  {{lyric}}
                </h4>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>




  </v-container>
</template>

<script>
// @ is an alias to /src
import SongListitem from '@/components/SongListitem.vue'
import Levels from '@/assets/json/levels.json'
import Songs from '@/assets/json/songs.json'

export default {
  name: 'SongPage',
  components: {
    SongListitem
  },
  data () {
    return {
        levels: Levels,
        songs: Songs,
    }
  },
  methods: {
    stopSound(){
      this.playlist[this.counter].pause();
      this.playlist[this.counter].currentTime = 0;
    },
  }
}
</script>

<style lang="scss">
// .background-music {
  // background-image: 
  // linear-gradient(to bottom, rgba(255, 255, 255, 0.932), rgba(255, 255, 255, 0.856), rgb(255, 255, 255)), 
  // url("../assets/images/musical-key.jpeg");
  // opacity: 80%;
  // height: 100%;
  // background-position: center;
// }
</style>